import { gql } from '@apollo/client';

export const LOGIN = gql`
  query Login($input: Login!) {
    Login(input: $input) {
      name,
      token
    }
  }
`;
