import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
	Button,
	Drawer,
	Comment,
	Avatar,
	Tooltip,
	Input,
	Form,
	Empty,
	Spin,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import "dayjs/locale/pt-br";
import relativeTime from "dayjs/plugin/relativeTime";

const { TextArea } = Input;

dayjs.locale("pt-br");
dayjs.extend(relativeTime);

const App = ({ visible, close, data, refetchTop }) => {
	const [state, setState] = useImmer({});
	const [messages, setMessages] = useImmer([]);


	const [load, conversation] = useLazyQuery(
		gql`
		query InvoiceConversation($invoice_id: String!) {
			InvoiceConversation(invoice_id: $invoice_id) {
				_id
				createdAt
				message
				author {
					name
					me
					user_type
				}
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	const [send, { loading: sendingLoading }] = useMutation(
		gql`
			mutation InvoiceMessage($invoice_id: String!, $message: String!) {
				InvoiceMessage(invoice_id: $invoice_id, message: $message) {
					_id
					createdAt
					message
					author {
						name
						me
						user_type
					}
				}
			}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	const sendMessage = async () => {
		let res = await send({
			variables: {
				invoice_id: data._id,
				message: state.message,
			},
		});

		if (res?.data?.InvoiceMessage?._id) {
			setMessages([...messages, res.data.InvoiceMessage]);
			setState({});
			refetchTop();
		}
	};

	useEffect(() => {
		async function initLoad() {
			load({
				variables: {
					invoice_id: data._id,
				},
			});
		}

		if (visible && data) {
			initLoad();
		}
	}, [visible, data]);

	useEffect(() => {
		if (conversation?.data?.InvoiceConversation?.length) {
			setMessages(conversation?.data?.InvoiceConversation);
		}
	}, [conversation]);

	const renderAuthor = (author) => {

		const getUserType = (type) => {
			if(type === 'partner') return 'Parceiro';
			if(type === 'partner_admin') return 'Adm. Parceiro';
			if(type === 'user') return 'MEI';
		}

		return (<div>{author.name} {!author.me ? (<div className={`badge ${author.user_type}`}>{getUserType(author.user_type)}</div>) : null}</div>);
	};

	return (
		<>
			<Drawer
				title="Mensagens"
				width={600}
				visible={visible}
				onClose={() => {
					setMessages([]);
					close();
				}}
			>
				<div className="ticket-conversation">
					<div className="ticket-conversation-messages">
						{conversation.loading ? (
							<div className="loading">
								<Spin />
							</div>
						) : null}

						{!messages.length &&
						conversation.called &&
						!conversation.loading ? (
							<div className="empty">
								<Empty description={"Nenhuma mensagem"} />
							</div>
						) : null}

						{messages?.length
							? messages.map((item) => {
									return (
										<Comment
											key={
												item?._id
													? item._id
													: Math.floor(Math.random() * 1000000000000000)
											}
											className={item?.author?.me ? 'me' : 'default'}
											actions={null}
											author={renderAuthor(item?.author)}
											avatar={null}
											content={item?.message}
											datetime={dayjs(item?.createdAt).fromNow()}
										/>
									);
							  })
							: null}
					</div>

					<div className="ticket-conversation-textarea">
						<Form onFinish={sendMessage}>
							<TextArea
								rows={4}
								value={state?.message ?? null}
								onChange={(e) => {
									setState((d) => {
										d.message = e.target.value;
									});
								}}
							/>
							<Button
								type="primary"
								style={{ marginTop: 20, float: "right" }}
								htmlType="submit"
								disabled={!state?.message?.length || sendingLoading}
								loading={sendingLoading}
							>
								Enviar
							</Button>
						</Form>
					</div>
				</div>
			</Drawer>
		</>
	);
};
export default App;
