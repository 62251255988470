import React, {useState} from 'react';
import { Form, Icon, Input, Button, Checkbox } from "antd";

//import "assets/scss/login.scss";
//import Logo from "assets/images/login-logo.png";

import useImperativeQuery from 'functions/hooks/useImperativeQuery';
import * as Queries from './Queries';
import Swal from 'sweetalert2';
import isEmail from 'validator/lib/isEmail';

import User from 'store/user';

function Login({history}) {

	const [state, setState] = useState({});
	const [loading, setLoading] = useState(false);

	const loginAction = useImperativeQuery(Queries.LOGIN);


	const login = async () => {

		try {

			if(!state.email || !isEmail(state.email)) return Swal.fire({
										title: "Erro",
										text: 'Por favor, insira um e-mail válido.',
										type: "error",
										confirmButtonText: "OK"
									});

			if(!state.password) return Swal.fire({
										title: "Erro",
										text: 'Por favor, insira uma senha válida.',
										type: "error",
										confirmButtonText: "OK"
									});
			setLoading(true);

			const {data, errors} = await loginAction({
				input: state
			});

			setLoading(false);

			if(!errors) {
				await User.setLogin(data.Login);
				history.replace("/");
			} else {
				const errorMessage = errors[0].message || 'Não foi possível fazer seu login no momento.';
				return Swal.fire({
					title: "Erro",
					text: errorMessage,
					type: "error",
					confirmButtonText: "OK"
				});
			}
		} catch(e) {
			console.log(e);
		}

	}




	  return (
	   		<div className="login">
	   			<div className="login-wrapper">
	   				<div className="login-inset">
	   					<div className="logo-wrapper">
							<img src={require(`themes/${process.env.REACT_APP_THEME}/images/login-logo.png`).default} alt=""/>
	   					</div>

	   					<Form
	   						onSubmit={null}
	   						layout={"vertical"}
	   						className="login-form"
	   					>
	   						<Form.Item>
	   								<Input
	   									rules={[{ required: true }]}
	   									prefix={
	   										<Icon
	   											type="mail"
	   											style={{
	   												color: "rgba(0,0,0,.25)"
	   											}}
	   										/>
	   									}
	   									placeholder="E-mail"
	   									size="large"
	   									onChange={event => {
	   										const { value } = event.target;
	   										setState(state => {
	   											return {
	   												...state,
	   												email: value
	   											}
	   										});
	   									}}
	   								/>
	   							
	   						</Form.Item>
	   						<Form.Item>
	   							
	   								<Input
	   									rules={[{ required: true }]}
	   									prefix={
	   										<Icon
	   											type="lock"
	   											style={{
	   												color: "rgba(0,0,0,.25)"
	   											}}
	   										/>
	   									}
	   									type="password"
	   									placeholder="Senha"
	   									size="large"
	   									onChange={event => {
	   										const { value } = event.target;
	   										setState(state => {
	   											return {
	   												...state,
	   												password: value
	   											}
	   										});
	   									}}
	   								/>
	   								
	   						</Form.Item>

	   						<div className="options">
	   							<Button
	   								size={"large"}
	   								loading={loading}
	   								onClick={login}
	   								type="primary"
	   								htmlType="submit"
	   								className="login-form-button"
	   							>
	   								Continuar
	   							</Button>
	   						
	   						</div>

	   						

	   						
	   					</Form>
	   				</div>
	   			</div>
	   		</div>
	  );
}

export default Login;
