import { gql } from "@apollo/client";

export const INVOICES = gql`
query Invoices($filters: InvoiceFilters, $page: Int!, $per_page: Int) {
  Invoices(filters: $filters, page: $page, per_page: $per_page) {
    invoices {
      _id
      taker
      cpfcnpj
      amount
      description
      notes
      status
      finishedAt
      createdAt
      fileURL
      job_uuid
      business_name
      business_id
      partner_company {
        name
      }
      user
      user_name
      service_code
      invoice_type
    }
    pages
    currentPage
    total
  }
}
`;

export const INVOICE_SAVE = gql`
mutation Invoice($id: String!, $input: InvoiceInput!) {
  Invoice(id: $id, input: $input) {
    _id
  }
}
`;

export const INVOICE_REQUEST = gql`
mutation Invoice($id: String, $input: InvoiceInput!) {
	Invoice(id: $id, input: $input) {
    _id
    taker
    cpfcnpj
    amount
    description
    notes
  }
}
`;
