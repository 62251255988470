import { gql, useQuery } from "@apollo/client";
import {
	Empty,
	List
} from "antd";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import moment from "moment";
import {
	StarFilled,
	StarOutlined
} from "@ant-design/icons";


function User(props) {
	const { id } = props.match.params;

	const { data, loading, error, refetch } = useQuery(
		gql`
      query PartnerReviews($user_id: String!) {
         PartnerReviews(user_id: $user_id) {
           rate
				description
				createdAt
         }
      }
   `,
		{
			variables: {
				user_id: id,
			},
			fetchPolicy: "no-cache",
		}
	);

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return <Error />;
	}

	console.log(data);

	return (
		<div>
			{!loading && data?.PartnerReviews?.length ? (
				<List
					itemLayout="horizontal"
					dataSource={data.PartnerReviews}
					renderItem={(item) => (
						<List.Item className="rate-item">
							<div className="rate-view">
								{Array(item.rate)
									.fill()
									.map(function (v, i) {
										return <StarFilled />;
									})}
								{Array(10 - item.rate)
									.fill()
									.map(function (v, i) {
										return <StarOutlined className="outline" />;
									})}
							</div>
							<div className="rate-description">
								{item.description}
								<em>{moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss")}</em>
							</div>
						</List.Item>
					)}
				/>
			) : null}
			{!loading && !data?.PartnerReviews?.length ? (
				<Empty/>
			) : null}
		</div>
	);
}

export default User;
