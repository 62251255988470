import React, { useState, useEffect } from "react";
import * as Queries from "./Queries";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import MaskedInput from "antd-mask-input";
import { statuses } from "./Misc";
import { Modal, Button, Col, Row, Layout, Form, Input, Select } from "antd";
import InputNumber from "components/InputNumber";
import { useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import { useImmer } from "use-immer";

function ModalView({ visible, refetch, data, close }) {
	const [state, setState] = useImmer({
		price: 0,
	});

	useEffect(() => {
		if (visible && data) {
			setState({
				...state,
				...data,
			});
		}
	}, [visible, data]);

	const [saveAction] = useMutation(Queries.SAVE_SERVICE);

	const save = async () => {
		try {

			console.log(state);

			const { _id, ...object } = state;

			console.log(object);

			if (!object.description)
				return Modal.error({
					content: "Por favor, insira uma descrição",
				});
			if (!object.status)
				return Modal.error({
					content: "Por favor, selecione um status",
				});

			const response = await saveAction({
				variables: {
					input: object,
					id: _id ? _id : null,
				},
			});

			if (!response.error) {
				close();
				refetch();
				setState({
					price: 0,
				});
			} else {
				Swal.fire({
					title: "Erro",
					text: "Não foi possível salvar no momento. Por favor, tente novamente mais tarde.",
					type: "error",
					confirmButtonText: "OK",
				});
			}
		} catch (e) {
			console.log(e);
			Swal.fire({
				title: "Erro",
				text: "Não foi possível salvar no momento. Por favor, tente novamente mais tarde.",
				type: "error",
				confirmButtonText: "OK",
			});
		}
	};


	useEffect(() => {
		setState(data);
	}, [data]);

	return (
		<Modal
			title="Serviço"
			visible={visible}
			onOk={save}
			onCancel={() => {
				setState(null);
				close();
			}}
		>
			{state ? (
				<Form layout="vertical">
					<Form.Item
						name="name"
						label="Nome"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input
							defaultValue={state.name}
							onChange={(e) => {
								const { value } = e.target;
								setState(d => {
									d.name = value;
								});
							}}
						/>
					</Form.Item>

					<Form.Item
						name="price"
						label="Preço"
						rules={[
							{
								required: true,
							},
						]}
					>
						<InputNumber
							defaultValue={state.price}
							onChange={(e) => {
								setState(d => {
									d.price = e;
								});
							}}
						/>
					</Form.Item>

					<Form.Item name={"description"} label="Descrição">
						<Input.TextArea
							defaultValue={state.description}
							onChange={(e) => {
								const { value } = e.target;
								setState(d => {
									d.description = value;
								});
							}}
						/>
					</Form.Item>

					<Form.Item label="Status">
						<Select
							defaultValue={statuses[state.status]}
							onChange={(e) => {
								setState(d => {
									d.status = parseInt(e);
								});
							}}
						>
							<Select.Option value="1">Inativo</Select.Option>
							<Select.Option value="2">Ativo</Select.Option>
							<Select.Option value="3">Eventual</Select.Option>
						</Select>
					</Form.Item>
				</Form>
			) : null}
		</Modal>
	);
}

export default ModalView;
