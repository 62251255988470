import { Drawer, Radio, Input, Select, Form, Button, DatePicker } from "antd";
import { ADMINS } from "scenes/tickets/Queries";
import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

export default function Filters({
	setFilters,
	filters,
	visible,
	close,
	filter,
}) {

	const [loadAdmins, admins] = useLazyQuery(ADMINS, {
		fetchPolicy: "no-cache",
	});

	useEffect(() => {
		if(visible) loadAdmins();
	}, [visible]);

	return (
		<Drawer
			title="Filtros"
			visible={visible}
			width={600}
			onClose={() => {
				close();
			}}
		>
			<Form layout="vertical">
				<Form.Item label={"Status"}>
					<Select
						onChange={(e) => {
							setFilters((d) => {
								d.status = e;
							});
						}}
						value={filters?.status}
					>
						<Select.Option value={"negotiating"}>Em negociação </Select.Option>
						<Select.Option value={"contract_sent"}>
							Contrato enviado
						</Select.Option>
						<Select.Option value={"contract_signed"}>
							Contrato assinado
						</Select.Option>
						<Select.Option value={"client_dropped"}>
							Cliente desistiu
						</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item label={"Tipo de Empresa"}>
					<Radio.Group
						onChange={(e) => {
							setFilters((d) => {
								d.type = e.target.value;
							});
						}}
						value={filters?.type}
					>
						<Radio value={""}>Qualquer</Radio>
						<Radio value={"Empresa"}>Empresa</Radio>
						<Radio value={"Contabilidade"}>Contabilidade</Radio>
					</Radio.Group>
				</Form.Item>

				<Form.Item label={"Nome da Empresa"}>
					<Input
						value={filters?.company_name}
						onChange={(e) => {
							setFilters((d) => {
								d.company_name = e.target.value;
							});
						}}
					/>
				</Form.Item>
				<Form.Item label={"Número de MEI's que possui"}>
					<Input
						type="number"
						value={filters?.meis}
						onChange={(e) => {
							setFilters((d) => {
								d.meis = e.target.value;
							});
						}}
					/>
				</Form.Item>
				<Form.Item label={"Nome do contato"}>
					<Input
						value={filters?.contact_name}
						onChange={(e) => {
							setFilters((d) => {
								d.contact_name = e.target.value;
							});
						}}
					/>
				</Form.Item>
				<Form.Item label={"Gênero"}>
					<Select
						onChange={(e) => {
							setFilters((d) => {
								d.contact_gender = e;
							});
						}}
						value={filters?.contact_gender}
					>
						<Select.Option value={"Homem"}>Homem</Select.Option>
						<Select.Option value={"Mulher"}>Mulher</Select.Option>
						<Select.Option value={"Outro"}>Outro</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item label={"Contato"}>
					<Input.Group compact>
						<Form.Item style={{ width: "40%" }}>
							<Select
								style={{ width: "100%" }}
								onChange={(e) => {
									setFilters((d) => {
										if (!d.contact) d.contact = {};
										d.contact.type = e;
									});
								}}
								value={filters.contacts_type}
							>
								<Select.Option value={"E-mail"}>E-mail</Select.Option>
								<Select.Option value={"WhatsApp"}>WhatsApp</Select.Option>
								<Select.Option value={"Telefone"}>Telefone</Select.Option>
								<Select.Option value={"Redes Sociais"}>
									Redes Sociais
								</Select.Option>
							</Select>
						</Form.Item>
						<Form.Item style={{ width: "60%" }}>
							<Input
								value={filters?.contacts_value}
								onChange={(e) => {
									setFilters((d) => {
										if (!d.contact) d.contact = {};
										d.contact.value = e.target.value;
									});
								}}
							/>
						</Form.Item>
					</Input.Group>
				</Form.Item>
				<Form.Item label={"Tempo de Existência"}>
					<Input
						value={filters?.age}
						onChange={(e) => {
							setFilters((d) => {
								d.age = e.target.value;
							});
						}}
					/>
				</Form.Item>
				<Form.Item label={"Núm. Colaboradores"}>
					<Input
						type="number"
						value={filters?.number_employees}
						onChange={(e) => {
							setFilters((d) => {
								d.number_employees = e.target.value;
							});
						}}
					/>
				</Form.Item>
				<Form.Item label={"Área de Marketing"}>
					<Select
						onChange={(e) => {
							setFilters((d) => {
								d.marketing_area = e;
							});
						}}
						value={filters?.marketing_area}
						mode="multiple"
					>
						<Select.Option value={"Não possui"}>Não possui</Select.Option>
						<Select.Option value={"Interna"}>Interna</Select.Option>
						<Select.Option value={"Externa"}>Externa</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item label={"Quais ferramentas utiliza?"}>
					<Select
						onChange={(e) => {
							setFilters((d) => {
								d.marketing_digital_tools = e;
							});
						}}
						value={filters?.marketing_digital_tools}
						mode="multiple"
					>
						<Select.Option value={"Conta Azul"}>Conta Azul</Select.Option>
						<Select.Option value={"Omie"}>Omie</Select.Option>
						<Select.Option value={"Dominio"}>Dominio</Select.Option>
						<Select.Option value={"Sage"}>Sage</Select.Option>
						<Select.Option value={"Outros"}>Outros</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item label={"Como chegou até nós"}>
					<Select
						onChange={(e) => {
							setFilters((d) => {
								d.reference = e;
							});
						}}
						value={filters?.reference}
					>
						<Select.Option value={"Google"}>Google</Select.Option>
						<Select.Option value={"Instagram"}>Instagram</Select.Option>
						<Select.Option value={"Facebook"}>Facebook</Select.Option>
						<Select.Option value={"Prospeção nossa"}>
							Prospeção nossa
						</Select.Option>
						<Select.Option value={"Outros"}>Outros</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item label={"Data de Cadastro"}>
					<DatePicker.RangePicker
						onChange={(e) => {
							console.log(e);

							setFilters((d) => {
								d.signup_date = e;
							});
						}}
						format={"DD/MM/YYYY"}
						style={{
							width: "100%",
						}}
						value={filters?.signup_date}
					/>
				</Form.Item>
				<Form.Item label={"SDR"}>
					<Input
						value={filters?.sdr}
						onChange={(e) => {
							setFilters((d) => {
								d.sdr = e.target.value;
							});
						}}
					/>
				</Form.Item>
				<Form.Item label={"Funil"}>
					<Select
						onChange={(e) => {
							setFilters((d) => {
								d.funnil = e;
							});
						}}
						value={filters?.funnil}
					>
						<Select.Option value={"Quente"}>Quente</Select.Option>
						<Select.Option value={"Morno"}>Morno</Select.Option>
						<Select.Option value={"Frio"}>Frio</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item label="Closer">
					<Select
						value={filters?.closer ?? null}
						loading={admins?.loading}
						onChange={(e) => {
							setFilters((d) => {
								d.closer = e;
							});
						}}
					>
						{admins?.data &&
							admins?.data?.Admins?.map((item, index) => {
								return (
									<Select.Option key={`admin_id_${index}`} value={item._id}>
										{item.name}
									</Select.Option>
								);
							})}
					</Select>
				</Form.Item>

				<div>
					<Button type="primary" onClick={filter}>
						Filtrar
					</Button>
				</div>
			</Form>
		</Drawer>
	);
}
