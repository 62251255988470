import React, { useEffect, useState } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

import User from "store/user";
import { Result } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useApolloClient, gql } from "@apollo/client";
import Emitter from "functions/emitter";
import jwt_decode from "jwt-decode";

function PrivateRoute({ component: Component }) {
	const client = useApolloClient();

	const [state, setState] = useState({
		status: "loading",
		path: null,
	});

	const logout = () => {
		User.logout().then(() => {
			setState({ loginRedirect: true });
		});
	};

	const checkJWT = () => {
		var decoded = jwt_decode(User.get("token"));
		if (decoded && decoded.exp) {
			if (Date.now() >= decoded.exp * 1000) {
				this.logout();
			}
		} else {
			this.logout();
		}
	};

	useEffect(() => {
		let interval;

		User.check(client).then(
			(result) => {
				setState({ status: "success" });

				// Watch for auth permissions
				interval = setInterval(() => {
					User.check(client)
						.then(async (result) => {
							if (!result) {
								await localStorage.removeItem("user");
								setState({ status: "redirect", path: "/login" });
							}
						})
						.catch(async (error) => {
							await localStorage.removeItem("user");
							setState({ status: "redirect", path: "/login" });
						});
				}, 30000);
			},
			async (e) => {
				await localStorage.removeItem("user");

				if (e === "server_error") {
					setState({ status: "server_error" });
				} else {
					setState({ status: "redirect", path: "/login" });
				}
			}
		);

		Emitter.on("LOGOUT", async function () {
			await localStorage.removeItem("user");
			setState({ status: "redirect", path: "/login" });
		});

		return () => {
			Emitter.off("LOGOUT");
			clearInterval(interval);
		};
	}, []);

	return (
		<Route
			render={(props) => {
				if (state.status === "server_error") {
					return (
						<div>
							<Result
								status="500"
								title="Ops"
								subTitle="Algo deu erro. Por favor, tente novamente mais tarde."
							/>
						</div>
					);
				}

				if (state.status === "redirect") {
					return (
						<Redirect
							to={{
								pathname: state.path,
								state: { from: props.location },
							}}
						/>
					);
				}

				if (state.status === "loading") {
					return (
						<div className="loading-view">
							<div className="spinner-wrapper">
								<LoadingOutlined />
							</div>
						</div>
					);
				}

				return <Component {...props} />;
			}}
		/>
	);
}

export default PrivateRoute;
