import React, { useState, useEffect, useReducer } from "react";
import { Button, Modal, Upload, Form, Alert } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import PartnerSearch from "components/PartnerSearch";

import Swal from "sweetalert2";

import { useMutation, gql } from "@apollo/client";

export default function Import({ refetch }) {
	const [saveAction] = useMutation(
		gql`mutation ProspectsImport($input: ProspectImportInput) {
        ProspectsImport(input: $input)
    }`
	);

	const [fileList, setFileList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [partner, setPartner] = useState(null);
	const [notCreated, setNotCreated] = useState([]);

	const save = async () => {
		if (!fileList.length) {
			return Swal.fire({
				title: "Erro",
				text: "Selecione um arquivo para importar",
				icon: "error",
			});
		}

		setNotCreated([]);
		setLoading(true);

		const { data, error } = await saveAction({
			variables: {
				input: {
					file: fileList[0].originFileObj,
				},
			},
		});

		setLoading(false);

		if (data.ProspectsImport && !error) {
			refetch();
			setVisible(false);
			Modal.success({
				content: "Importação realizada com sucesso",
			});
		} else {
			Modal.error({
				content: "Não foi possível executar a importação",
			});
		}
	};

	return (
		<div>
			<Button type="default" onClick={() => setVisible(true)}>
				Importar
			</Button>

			<Modal
				title="Importar Prospecções"
				visible={visible}
				onOk={() => {
					save();
				}}
				onCancel={() => {
					if (!loading) {
						setVisible(false);
						setNotCreated([]);
						setFileList([]);
					}
				}}
				footer={[
					<Button
						key="back"
						loading={loading}
						onClick={() => {
							if (!loading) {
								setVisible(false);
							}
						}}
					>
						Cancelar
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => {
							if (!loading) {
								save();
							}
						}}
					>
						Importar
					</Button>,
				]}
			>
				<Form layout="vertical">
					<Form.Item label="Arquivo CSV">
						<Upload
							accept={"text/csv"}
							customRequest={(data) => {
								console.log(data);
							}}
							onChange={({ fileList }) => {
								fileList[0].status = "done";
								setFileList(fileList);
							}}
							fileList={fileList}
							maxCount={1}
						>
							<Button icon={<UploadOutlined />}>Selecionar arquivo</Button>
						</Upload>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
}
