 var a = "9",
        b = "A",
        c = "S",
        d = [8, 9, 16, 17, 18, 36, 37, 38, 39, 40, 91, 92, 93],
        e = function(a) {
            for (var b = 0, c = d.length; c > b; b++)
                if (a == d[b]) return !1;
            return !0
        },
        f = function(a) {
            return a = a || {}, a = {
                precision: a.hasOwnProperty("precision") ? a.precision : 2,
                separator: a.separator || ",",
                delimiter: a.delimiter || ".",
                unit: a.unit && a.unit.replace(/[\s]/g, "") + " " || "",
                suffixUnit: a.suffixUnit && " " + a.suffixUnit.replace(/[\s]/g, "") || "",
                zeroCents: a.zeroCents,
                lastOutput: a.lastOutput
            }, a.moneyPrecision = a.zeroCents ? 0 : a.precision, a
        },
        g = function(a) {
            this.elements = a
        };

module.exports = function(d, e) {    
    var f, g = "object" == typeof e ? e.pattern : e,
        h = g.replace(/\W/g, ""),
        i = g.split(""),
        j = d.toString().replace(/\W/g, ""),
        k = j.replace(/\W/g, ""),
        l = 0,
        m = i.length;
    for (f = 0; m > f; f++) {
        if (l >= j.length) {
            if (h.length == k.length) return i.join("");
            break
        }
        i[f] === a && j[l].match(/[0-9]/) || i[f] === b && j[l].match(/[a-zA-Z]/) || i[f] === c && j[l].match(/[0-9a-zA-Z]/) ? i[f] = j[l++] : (i[f] === a || i[f] === b || i[f] === c) && (i = i.slice(0, f))
    }
    return i.join("").substr(0, f)
}
