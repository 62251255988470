import React, {useState, useEffect} from 'react';
import { useQuery, useMutation } from "@apollo/client";
import { Modal, Button, Form, Input, Select } from 'antd';
import Swal from 'sweetalert2';
import isEmail from 'validator/lib/isEmail';

import InputPhone from 'components/Inputs/Phone';
import MaskedInput from 'antd-mask-input';

function Employee({data, visible, close, onSave, dataIndex}) {


	
	useEffect(() => {
		if(visible && data) {
			setState(data);
		}
	}, [visible])

	const [state, setState] = useState({
		status: '1'
	});
	
	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK"
		});
	}

	const save = () => {

		if(!state.name)
			return ErrorDialog('Por favor, informe um nome válido.');
		if(!state.role)
			return ErrorDialog('Por favor, informe um cargo válido.');
		if(!state.cpf)
			return ErrorDialog('Por favor, informe um CPF válido.');

		let obj = {
			...data,
			...state
		};
		onSave(obj, dataIndex)
		close();
	}

	const cancel = () => {
		close();
	}
	
	return (
		<Modal 
			title="Funcionário" 
			visible={visible} 
			onOk={save} 
			onCancel={cancel}
		>
		  
			<Form layout="vertical">
				
				<Form.Item label="Nome">
					<Input 
						value={state.name || ''}
						onChange={(e) => {
							const {value} = e.target;
							setState(state => {
								return {
									...state,
									name: value
								}
							})
						}}
					/>
				</Form.Item>
				<Form.Item label="Cargo">
					<Input 
						value={state.role || ''}
						onChange={(e) => {
							const {value} = e.target;
							setState(state => {
								return {
									...state,
									role: value
								}
							})
						}}
					/>
				</Form.Item>
				<Form.Item label="E-mail">
					<Input 
						value={state.email || ''}
						onChange={(e) => {
							const {value} = e.target;
							setState(state => {
								return {
									...state,
									email: value
								}
							})
						}}
					/>
				</Form.Item>
				<Form.Item label="Telefone">
					<InputPhone 
						value={state.phone || ''}
						onChange={(e) => {
							const {value} = e.target;
							setState(state => {
								return {
									...state,
									phone: value
								}
							})
						}}
					/>
				</Form.Item>
				<Form.Item label="CPF">
					<MaskedInput
						mask="111.111.111-11"  
						value={state.cpf || ''}
						onChange={(e) => {
							const {value} = e.target;
							setState(state => {
								return {
									...state,
									cpf: value
								}
							})
						}}
					/>
				</Form.Item>
				<Form.Item label="Status">
					<Select 
						value={state.status}
						onChange={(e) => {
							setState(state => {
								return {
									...state,
									status: e
								}
							})
						}}
					>
						<Select.Option value="1">Ativo</Select.Option>
						<Select.Option value="2">Inativo</Select.Option>
					</Select>
				</Form.Item>

			</Form>

		</Modal>
	);
}

export default Employee;
