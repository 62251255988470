import React, { useState, useEffect, useReducer } from "react";
import * as Queries from "./Queries";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

import Loading from "components/Loading/Page";
import Error from "components/Error/Page";

import {
  EditOutlined,
  MailOutlined,
  LockOutlined,
  MessageOutlined,
} from "@ant-design/icons";

import MaskedInput from "antd-mask-input";
import {
  Col,
  Row,
  Layout,
  Table,
  Typography,
  Button,
  Select,
  Form,
  Input,
  Popconfirm,
  notification,
  Popover,
  Tooltip,
} from "antd";
import { statuses } from "./Misc";

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

function PartnerGroup(props) {
 
  return (
    <Layout className={"page-wrapper"}>
      <Content className="site-layout-background">
        <div className="page-title">
          <Title>Grupos de Parceiros</Title>
          <Button
            type="primary"
            onClick={() => {
              
            }}
          >
            Novo Grupo
          </Button>
        </div>

      
      </Content>
    </Layout>
  );
}

export default PartnerGroup;
