import { gql } from '@apollo/client';

export const COMPANIES = gql`
query Companies {
  Companies {
    _id
    cnpj
    company_name
    trade_name
    phone
    email
  }
}
`;

export const COMPANY_GET = gql`
query Company($id: String) {
  Company(id: $id) {
    _id
    cnpj
    company_name
    trade_name
    phone
    mobile
    email
    date_start
    date_end
    contact_name
    employees {
        _id
        name
        role
        phone
        cpf
        email
        status
    }
  }
}
`;

export const COMPANY_SAVE = gql`
mutation Company($input: CompanyInput) {
  Company(input: $input) {
    _id
    cnpj
    company_name
    trade_name
    phone
    mobile
    email
    date_start
    date_end
    contact_name
    employees {
        _id
        name
        role
        phone
        cpf
        email
        status
    }
  }
}
`;


export const COMPANY_REMOVE = gql`
mutation CompanyRemove($id: String!) {
  CompanyRemove(id: $id)
}
`;

export const EMPLOYEE_REMOVE = gql`
mutation CompanyEmployeeRemove($id: String!) {
  CompanyEmployeeRemove(id: $id)
}
`;