import React, { useState, useEffect } from "react";
import { Modal, Input, Form} from "antd";
import { useMutation } from "@apollo/client";
import * as Queries from "./Queries";

const Observations = ({ visible, setModal, data, refetch }) => {

   const [state, setState] = useState(null);
   const [saveTicket, { loading: loadingSave }] = useMutation(
     Queries.TICKET_OBSERVATION
   );

   useEffect(() => {
       if (data) {
             const {ticketId, observation} = data;
             setState({ticketId, observation});
         }
   }, [data]);

   const save = async () => {
      console.log(state);

      const res = await saveTicket({
         variables: {
            id: state.ticketId,
            observation: state.observation
         }
      });

      refetch();

      setModal((modal) => {
        return {
          ...modal,
          visible: false
        };
      });
   }

  return (
    <Modal
      title="Observações"
      visible={visible}
      onOk={save}
      loading={loadingSave}
      onCancel={() => {
        setModal((modal) => {
          return {
            ...modal,
            visible: false,
          };
        });
      }}
    >
      <Form layout={'vertical'}>
        <Form.Item label="Observações">
          <Input.TextArea 
            rows={15}
            value={state?.observation}
            onChange={(e) => {
               setState((state) => {
                     return {
                           ...state,
                           observation: e.target.value
                     }
               })
            }}
         />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Observations;