import React, { useState, useEffect, useRef } from "react";
import InputMask from "react-input-mask";

const Input = ({value, ...props}) => {


 	const beforeMaskedValueChange = (newState: InputState) => {
 	   let { value } = newState;

 	   const newValue = value.replace(/\D/g, "");
 	   if (newValue.length === 11) {
 	     value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
 	   }

 	   return {
 	     ...newState,
 	     value
 	   };
 	 };

	return (
		<div className="ant-form-item-control-input">
			<div className="ant-form-item-control-input-content">
				<InputMask
					value={value || null}
					mask="(99) 9999-99999" 
					maskChar={null} 
					beforeMaskedValueChange={beforeMaskedValueChange}
					{...props}
					className={'ant-input'}
				/>
			</div>
		</div>
	)
}

export default Input;