import { useState, useEffect } from "react";
import * as Queries from "../Queries";
import { useQuery, useMutation, gql } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import InputNumber from "components/InputNumber";
import {
	Col,
	Row,
	Layout,
	Typography,
	Button,
	Form,
	Input,
	notification,
	Divider,
	Modal,
	Select,
} from "antd";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import { useDebounce } from "@react-hook/debounce";

import { useImmer } from "use-immer";

import { InfoCircleOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Title } = Typography;

function User(props) {
	const [state, setState] = useImmer({});
	const { id } = props.match.params;

	const { data, loading, error, refetch } = useQuery(
		gql`
		query PartnerAccesses($user_id: String) {
			PartnerAccesses(user_id: $user_id) {
				credentials_maquineta_login
				credentials_maquineta_value
				credentials_asaas_token
				asaas_webhook_status
				credentials_pagarme_apikey
				credentials_pagarme_password
				gateway_pix
				gateway_credit_card
				gateway_boleto
			}
			}
		`,
		{
			fetchPolicy: "no-cache",
			variables: {
				user_id: id,
			},
			onCompleted: (data) => {
				console.log(data);
				setState(data.PartnerAccesses);
			},
			onError: (err) => {
				console.log(err);
			},
		}
	);

	const [saveAction, { loading: loadingSave }] = useMutation(gql`
		mutation PartnerAccesses($input: PartnerAccessInput, $user_id: String!) {
			PartnerAccesses(input: $input, user_id: $user_id)
		}
	`);

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	const save = async () => {
		try {
			const {asaas_webhook_status, ...currentState} = state;
			let input = {...currentState };

			let res = await saveAction({
				variables: {
					input,
					user_id: id !== "new" ? id : null,
				},
			});

			if (res?.data?.PartnerAccesses) {
				Modal.success({
					title: "Informações atualizadas",
				});
			} else {
				Modal.error({
					title: "Erro",
					content: "Não foi possível atualzar no momento",
				});
			}
		} catch (e) {
			ErrorDialog("Erro ao salvar o parceiro. Por favor, tente novamente.");
		}
	};

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return <Error />;
	}

	const getStatusError = (status) => {
		if (!status) return null;
		if (status === "success") return "success";
		if (status === "error") return "error";
	};

	const GATEWAYS = [
		{ name: "Asaas", value: "asaas" },
		{ name: "Pagar.me", value: "pagarme" },
		{ name: "Maquineta Virtual", value: "maquinetavirtual" },
	];

	return (
		<Form layout="vertical">
			<br />

			<Row gutter={30}>
				<Col span={12}>
					<Title level={5}>Maquineta Virtual</Title>

					<Row gutter={20}>
						<Col span={12}>
							<Form.Item label="Login">
								<Input.Password
									value={state.credentials_maquineta_login || null}
									onChange={(e) => {
										setState((draft) => {
											draft.credentials_maquineta_login = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="Senha"
								tooltip={{
									title: "Não informar caso não deseje atualizar.",
									icon: <InfoCircleOutlined />,
								}}
							>
								<Input.Password
									value={state.credentials_maquineta_password || null}
									onChange={(e) => {
										setState((draft) => {
											draft.credentials_maquineta_password = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
					</Row>

					<Divider />

					<Title level={5}>Asaas</Title>

					<Form.Item
						label="Token"
						hasFeedback={state.asaas_webhook_status !== null ? true : false}
						validateStatus={getStatusError(state.asaas_webhook_status)}
					>
						<Input.Password
							value={state.credentials_asaas_token || null}
							onChange={(e) => {
								setState((draft) => {
									draft.credentials_asaas_token = e.target.value;
								});
							}}
						/>
					</Form.Item>

					<Divider />

					<Title level={5}>Pagar.me</Title>

					<Row gutter={20}>
						<Col span={12}>
							<Form.Item label="API Key">
								<Input.Password
									value={state.credentials_pagarme_apikey || null}
									onChange={(e) => {
										setState((draft) => {
											draft.credentials_pagarme_apikey = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Senha">
								<Input.Password
									value={state.credentials_pagarme_password || null}
									onChange={(e) => {
										setState((draft) => {
											draft.credentials_pagarme_password = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Col>
				<Col span={12}>
					<Title level={5}>Gateways de Pagamento</Title>

					<p>Selecionar e mapear as formas de pagamento para cada Gateway.</p>

					<Row gutter={20}>
						<Col span={8}>
							<Form.Item label="Cartão de Crédito">
								<Select
									allowClear
									value={state.gateway_credit_card || null}
									onChange={(e) => {
										setState((draft) => {
											draft.gateway_credit_card = e;
										});
									}}
								>
									{GATEWAYS.map((item) => {
										return (
											<Select.Option value={item.value}>
												{item.name}
											</Select.Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="PIX">
								<Select
									allowClear
									value={state.gateway_pix || null}
									onChange={(e) => {
										setState((draft) => {
											draft.gateway_pix = e;
										});
									}}
								>
									{GATEWAYS.map((item) => {
										return (
											<Select.Option value={item.value}>
												{item.name}
											</Select.Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Boleto">
								<Select
									allowClear
									value={state.gateway_boleto || null}
									onChange={(e) => {
										setState((draft) => {
											draft.gateway_boleto = e;
										});
									}}
								>
									{GATEWAYS.map((item) => {
										return (
											<Select.Option value={item.value}>
												{item.name}
											</Select.Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
					</Row>
				</Col>
			</Row>

			<div>
				<Button
					onClick={save}
					type="primary"
					loading={loadingSave}
					style={{
						marginTop: 20,
						float: "right",
					}}
				>
					Salvar
				</Button>
			</div>
		</Form>
	);
}

export default User;
