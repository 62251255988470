import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ApolloProvider } from '@apollo/client/react';

import "app-styles";
// import 'assets/scss/main.scss';

import { ConfigProvider } from 'antd';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { useThemeSwitcher } from "react-css-theme-switcher";

//
// Routes
//

import PrivateRoute from 'functions/privateRoute';
import Login from 'scenes/login';
import Main from 'scenes/main';


//
// Apollo
//

import Client from 'functions/apollo';

//
// Locales
//

import ptBR from 'antd/lib/locale/pt_BR';
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};


if(process?.env?.REACT_APP_TITLE) {
  document.title = process?.env?.REACT_APP_TITLE;
}

function App() {
  
  const [loaded, setLoaded] = useState(false);
  const [defaultTheme, setDefaultTheme] = useState("light");
  
  useEffect(() => {
    
    async function getCurrentTheme() {
        let theme = await window.localStorage.getItem("theme");
        if(theme === 'dark') {
            setDefaultTheme("dark");
        }
    }
    getCurrentTheme();

  }, []);

  
  
  return (		
      <ThemeSwitcherProvider themeMap={themes} defaultTheme={defaultTheme}>
  			<ConfigProvider locale={ptBR}>
  			  	  			<ApolloProvider client={Client}>
    			  		   			<Router>
      			  						<Switch>
      			  							<Route path="/login" component={Login} />
      			  							<PrivateRoute path="/" component={Main} client={Client} />
      			  						</Switch>
    			  					  </Router>
  			  	  			</ApolloProvider>
  			</ConfigProvider>
    </ThemeSwitcherProvider>
  );
}


export default App;
