import { Layout, Typography, Button, Table, Badge, Tag } from "antd";
import { useImmer } from "use-immer";

import UAU from "./UAU";
import { gql, useQuery } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import dayjs from "dayjs";
import { EditOutlined } from "@ant-design/icons";
import Filters from "./Filters";

const { Content } = Layout;
const { Title } = Typography;

function UAUList() {
	const [modal, setModal] = useImmer({
		visible: false,
	});

	const [filterModal, setFilterModal] = useImmer(false);
	const [filters, setFilters] = useImmer({});

	const { data, loading, error, refetch } = useQuery(gql`
		query UserUAUs($filters: UserUAUFilters) {
			UserUAUs(filters: $filters) {
				type
				company_name
				signup_date
				funnil
				meis
				status
				_id
			}
		}
	`);

	const filter = () => {
		let payload = { ...filters };
		if (payload.meis) payload.meis = parseInt(payload.meis);
		if (payload.number_employees)
			payload.number_employees = parseInt(payload.number_employees);
		if(payload.signup_date) {
			payload.signup_date = {
				start: payload.signup_date[0].toISOString(),
				end: payload.signup_date[1].toISOString()
			}
		}

		console.log(payload);

		refetch({
			filters: payload,
		});
	};

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>UAU CRM</Title>
					<div style={{
						display: 'flex',
						gap: 10
					}}>
						<Button
							onClick={() => {
								setFilterModal(true);
							}}
						>
							Filtros
						</Button>
						<Button
							type="primary"
							onClick={() => {
								setModal({
									visible: true,
								});
							}}
						>
							Novo
						</Button>
					</div>
				</div>

				<Filters
					visible={filterModal}
					close={() => {
						setFilterModal(false);
					}}
					setFilters={setFilters}
					filters={filters}
					filter={filter}
				/>

				<div className="dashboard">
					{loading ? <Loading /> : null}

					{!loading && !error && data ? (
						<div>
							<Table
								rowKey="_id"
								dataSource={data?.UserUAUs}
								pagination={{
									hideOnSinglePage: true,
								}}
								columns={[
									{
										key: "type",
										dataIndex: "type",
										title: "Tipo",
									},
									{
										key: "company_name",
										dataIndex: "company_name",
										title: "Nome da Empresa",
									},
									{
										key: "status",
										dataIndex: "status",
										title: "Status",
										render: (value) => {
											if (value === "negotiating")
												return <Tag color="cyan">Negociando</Tag>;
											if (value === "contract_sent")
												return <Tag color="orange">Contrato Enviado</Tag>;
											if (value === "contract_signed")
												return <Tag color="green">Contrato Assinado</Tag>;
											if (value === "client_dropped")
												return <Tag color="default">Cliente desistiu</Tag>;
										},
									},
									{
										key: "funnil",
										dataIndex: "funnil",
										title: "Funil",
										render: (value) => {
											if (value === "Frio")
												return <Badge color="cyan" text="Frio" />;
											if (value === "Quente")
												return <Badge color="volcano" text="Quente" />;
											if (value === "Morno")
												return <Badge color="orange" text="Morno" />;
											return "";
										},
									},
									{
										key: "signup_date",
										dataIndex: "signup_date",
										title: "Data de Cadastro",
										render: (value) =>
											value ? dayjs(value).format("DD/MM/YYYY") : "-",
									},
									{
										key: "meis",
										dataIndex: "meis",
										title: "MEIs",
									},
									{
										key: "action",
										dataIndex: "action",
										title: "",
										width: 100,
										render: (value, row) => {
											return (
												<Button
													onClick={() => {
														setModal({
															visible: true,
															id: row._id,
														});
													}}
												>
													Editar <EditOutlined />
												</Button>
											);
										},
									},
								]}
							/>
						</div>
					) : null}

					{!loading && error ? <Error refetch={() => refetch()} /> : null}
				</div>

				<UAU
					{...modal}
					refetch={() => {
						refetch();
					}}
					close={() => {
						setModal({
							visible: false,
						});
					}}
				/>
			</Content>
		</Layout>
	);
}

export default UAUList;
