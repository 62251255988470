import React, { useState, useEffect, useRef } from "react";
import InputMask from "react-input-mask";

const Input = ({value, ...props}) => {


    const maskBuilder = v => {
        if (!v || v.length == 0) return '';
        return v.length < 15 ? "999.999.999-999" : "99.999.999/9999-99";
    }

	return (
		<div className="ant-form-item-control-input">
			<div className="ant-form-item-control-input-content">
				<InputMask
					maskChar=""
					mask={maskBuilder(value)}
					value={value || ''}
					{...props}
					className={'ant-input'}
				/>
			</div>
		</div>
	)
}

export default Input;