import { Modal, Form, Input, Select, Upload, Button } from "antd";
import { useImmer } from "use-immer";
import {
	UploadOutlined,
	FileDoneOutlined,
	FilePdfFilled,
} from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { useEffect } from "react";
import PartnerSearch from "components/PartnerSearch";

function ModalView({ visible, refetch, data, id, close }) {
	const [state, setState] = useImmer({});

	const [save, { loading }] = useMutation(gql`
		mutation CheckoutCreate($url: String!, $partner_id: String!) {
			CheckoutCreate(url: $url, partner_id: $partner_id)
		}
	`);

	const onSubmit = async () => {
		if (!state.url)
			return Modal.error({
				content: "Por favor, informe uma URL válida.",
			});

		let url;

		console.log(state);

		try {
			url = new URL(state.url);
		} catch (error) {
			return Modal.error({
				content: "Por favor, informe uma URL válida.",
			});
		}

		if (!url?.hostname)
			return Modal.error({
				content: "Por favor, informe uma URL válida.",
			});

		if (!state.partner_id)
			return Modal.error({
				content: "Por favor, informe um parceiro.",
			});

		try {

			const res = await save({
				variables: {
					url: state.url,
					partner_id: state.partner_id
				},
			});



			if (res.data.CheckoutCreate) {
				close();
				refetch();
				setState({});
			} else {
				Modal.error({
					content: "Não foi possível salvar no momento.",
				});
			}
		} catch (error) {
			Modal.error({
				content: "Não foi possível salvar no momento.",
			});
		}
	};


	return (
		<Modal
			title="Novo Checkout"
			visible={visible}
			onCancel={() => {
				close();
				setState({});
			}}
			footer={[
				<Button key="back" onClick={close} disabled={loading}>
					Cancelar
				</Button>,
				<Button
					type="primary"
					loading={loading}
					disabled={loading}
					onClick={onSubmit}
				>
					Enviar
				</Button>,
			]}
		>
			<Form layout="vertical">
				<Form.Item label="URL" help={`Com https/http.`}>
					<Input
						value={state.url}
						onChange={(e) => {
							setState((d) => {
								d.url = e.target.value;
							});
						}}
						
					/>
				</Form.Item>

				<Form.Item label={"Parceiro"}>
					<PartnerSearch
						defaultValue={state?.partner_name || null}
						onChange={(e, item) => {
							setState((d) => {
								d.partner_id = e;
								d.partner_name = item.name;
							});
						}}
						additionalProps={{
							allowClear: true,
						}}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}

export default ModalView;
