var estados = [
                 {
                    "cod_ibge": "12",
                    "text": "Acre",
                    "value": "AC"
                },
                {
                    "cod_ibge": "27",
                    "text": "Alagoas",
                    "value": "AL"
                },
                {
                    "cod_ibge": "16",
                    "text": "Amapá",
                    "value": "AP"
                },
                {
                    "cod_ibge": "13",
                    "text": "Amazonas",
                    "value": "AM"
                },
                {
                    "cod_ibge": "29",
                    "text": "Bahia",
                    "value": "BA"
                },
                {
                    "cod_ibge": "23",
                    "text": "Ceará",
                    "value": "CE"
                },
                {
                    "cod_ibge": "53",
                    "text": "Distrito Federal",
                    "value": "DF"
                },
                {
                    "cod_ibge": "32",
                    "text": "Espírito Santo",
                    "value": "ES"
                },
                {
                    "cod_ibge": "52",
                    "text": "Goiás",
                    "value": "GO"
                },
                {
                    "cod_ibge": "21",
                    "text": "Maranhão",
                    "value": "MA"
                },
                {
                    "cod_ibge": "51",
                    "text": "Mato Grosso",
                    "value": "MT"
                },
                {
                    "cod_ibge": "50",
                    "text": "Mato Grosso do Sul",
                    "value": "MS"
                },
                {
                    "cod_ibge": "31",
                    "text": "Minas Gerais",
                    "value": "MG"
                },
                {
                    "cod_ibge": "15",
                    "text": "Pará",
                    "value": "PA"
                },
                {
                    "cod_ibge": "25",
                    "text": "Paraíba",
                    "value": "PB"
                },
                {
                    "cod_ibge": "41",
                    "text": "Paraná",
                    "value": "PR"
                },
                {
                    "cod_ibge": "26",
                    "text": "Pernambuco",
                    "value": "PE"
                },
                {
                    "cod_ibge": "22",
                    "text": "Piauí",
                    "value": "PI"
                },
                {
                    "cod_ibge": "33",
                    "text": "Rio de Janeiro",
                    "value": "RJ"
                },
                {
                    "cod_ibge": "24",
                    "text": "Rio Grande do Norte",
                    "value": "RN"
                },
                {
                    "cod_ibge": "43",
                    "text": "Rio Grande do Sul",
                    "value": "RS"
                },
                {
                    "cod_ibge": "11",
                    "text": "Rondônia",
                    "value": "RO"
                },
                {
                    "cod_ibge": "14",
                    "text": "Roraima",
                    "value": "RR"
                },
                {
                    "cod_ibge": "42",
                    "text": "Santa Catarina",
                    "value": "SC"
                },
                {
                    "cod_ibge": "35",
                    "text": "São Paulo",
                    "value": "SP"
                },
                {
                    "cod_ibge": "28",
                    "text": "Sergipe",
                    "value": "SE"
                },
                {
                    "cod_ibge": "17",
                    "text": "Tocantins",
                    "value": "TO"
                }
            ];

var _ = require('lodash');

module.exports  = function(type, value) {
    if(type == 'all') {
        return estados;
    }
    if(type == 'search') {
        var result = _.filter(estados, {value: value});
        if(result && result[0]) {
            return result[0];
        }
    }
}