import { gql } from '@apollo/client';

export const USERS = gql`
query Users($filters: UserListFilters) {
  Users(filters: $filters) {
    _id,
    email,
    name,
    cpf,
    phone,
    status,
    plan,
    updatedAt,
    createdAt
  }
}
`;