import React from 'react';

import { Result, Button } from 'antd';


function Loading({refetch}) {
	return (
		<div className="error-page-view">
			<Result
			    status="warning"
			    title="Não foi possível carregar o conteúdo"
			    extra={
			      <Button type="primary" key="console" onClick={refetch}>
			        Recarregar
			      </Button>
			    }
			  />
		</div>
	);
}

export default Loading;