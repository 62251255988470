import { Select, Spin } from "antd";
import { useLazyQuery } from "@apollo/client";
import debounce from "functions/debounce";
import { gql } from "@apollo/client";
import { useEffect, useState } from "react";

const { Option } = Select;

const PARTNER_SEARCH = gql`
  query PartnerSearch($term: String) {
    PartnerSearch(term: $term) {
      _id
      name
    }
  }
`;

const PartnerSearch = ({
	onChange,
	defaultValue,
	disabled,
	additionalProps,
}) => {
	const [value, setValue] = useState(null);
	const [search, { loading, data: response }] = useLazyQuery(PARTNER_SEARCH);

	const onSearch = debounce((e) => searchAction(e), 500);

	const searchAction = async (term) => {
		await search({
			variables: {
				term,
			},
		});
	};

	useEffect(() => {
		if (defaultValue) setValue(defaultValue);
	}, [defaultValue]);

	return (
		<Select
			disabled={disabled}
			showSearch
			showArrow={false}
			filterOption={false}
			onSearch={onSearch}
			notFoundContent={loading ? <Spin size="small" /> : null}
			onChange={(e) => {
				if (e) {
					let item = response.PartnerSearch.find((i) => i._id === e);
					onChange(e, item);
					setValue(item.name);
				} else {
					onChange(e, null);
					setValue(null);
				}
			}}
			placeholder="Buscar parceiros"
			value={value}
			{...additionalProps}
		>
			{response?.PartnerSearch?.length &&
				response.PartnerSearch.map((user) => {
					return (
						<Option key={user._id} value={user._id}>
							{user.name}
						</Option>
					);
				})}
		</Select>
	);
};

export default PartnerSearch;
