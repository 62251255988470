import React, { useState, useEffect } from "react";
import * as Queries from "./Queries";
import { useMutation, useLazyQuery, gql } from "@apollo/client";
import _ from "lodash";
import {
	Modal,
	Button,
	Form,
	Select,
	Descriptions,
	Spin,
	notification,
} from "antd";
import { useImmer } from "use-immer";
import Swal from "sweetalert2";

function ModalView({ visible, refetch, close, data }) {
	const [generate, { loading }] = useMutation(gql`
	mutation InvoiceGenerate($id: String!, $code: String!) {
		InvoiceGenerate(id: $id, code: $code)
	}
	`);
	const [loadCodes, codes] = useLazyQuery(gql`
	{
		InvoicesCodes {
			id
			text
		}
	}
	`);

	const [state, setState] = useImmer({});
	const [info, setInfo] = useImmer({});
	const onClose = () => {
		setState({});
		setInfo({});
		close();
	};

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	useEffect(() => {
		if (visible) {
			setInfo(data);

			if(data?.service_code) {
				setState(d => {
					d.code = data.service_code;
				})
			}

			loadCodes();
		}
	}, [data, visible]);

	const confirmResend = async () => {
		return new Promise((resolve, reject) => {
			Modal.confirm({
				title: "Reenviar",
				content: "Esta nota fiscal já foi enviada. Deseja reenviar?",
				onCancel: () => {
					resolve(false);
				},
				onOk: () => {
					resolve(true);
				}
			});
		});
	}

	const save = async () => {
		if (info.status === "pending") {

			let proceed = true;

			if(info.job_uuid) {
				let confirm = await confirmResend();
				if(!confirm) return;
			}


			if (!state.code)
				return ErrorDialog(
					"Por favor, selecione um código de tributação nacional."
				);

			try {
				let res = await generate({
					variables: {
						id: data._id,
						...state,
					},
				});

				if (res?.data?.InvoiceGenerate) {
					notification.success({
						message: "Nota enviada para automatização",
					});
					close();
					refetch();
				} else {
					ErrorDialog("Não foi possível gerar no momento.");
				}

				console.log(res);
			} catch (error) {
				ErrorDialog(error.message || "Não foi possível gerar no momento.");
			}
		} else {
			ErrorDialog("Nota Fiscal já gerada.");
		}
	};

	const getInvoiceType = (type) => {
		if(!type) return '-';
		if(type === 'service') return 'Serviço';
		if(type === 'product') return 'Produto';
	}

	return (
		<Modal
			title="Nota Fiscal"
			visible={visible}
			onOk={save}
			width={800}
			onCancel={onClose}
			footer={[
				<Button key="back" onClick={onClose} disabled={loading}>
					Cancelar
				</Button>,
				<Button
					type="primary"
					loading={loading}
					disabled={loading}
					onClick={save}
				>
					{loading ? "Enviando" : "Gerar"}
				</Button>,
			]}
		>
			<Descriptions title="Sobre a Nota" bordered size={"small"} column={1}>
				<Descriptions.Item label="Nome da Empresa">
					{info?.business_name || "--"}
				</Descriptions.Item>
				<Descriptions.Item label="Tomador">
					{info?.taker || "--"}
				</Descriptions.Item>
				<Descriptions.Item label="CPF/CNPJ">
					{info?.cpfcnpj || "--"}
				</Descriptions.Item>
				<Descriptions.Item label="Valor">
					{info?.amount?.toLocaleString("pt-BR", {
						style: "currency",
						currency: "BRL",
					}) || "--"}
				</Descriptions.Item>
				<Descriptions.Item label="Descrição">
					{info?.description || "--"}
				</Descriptions.Item>
				<Descriptions.Item label="Observações">
					{info?.notes || "--"}
				</Descriptions.Item>
				<Descriptions.Item label="Tipo">
					{getInvoiceType(info?.invoice_type)}
				</Descriptions.Item>
				{info?.invoice_type === "service" ? (
					<Descriptions.Item label="Código Tributação Nacional">
						{info.service_code}
					</Descriptions.Item>
				) : null}
			</Descriptions>

			{info?.status === "pending" ? (
				<Form layout="vertical" style={{ marginTop: 20 }}>
					<Form.Item label="Código de Tributação Nacional">
						<Select
							showSearch
							style={{ width: "100%" }}
							disabled={loading}
							filterOption={(inputValue, option) => {
								return (
									option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >
									-1
								);
							}}
							onChange={(e) => {
								setState((d) => {
									d.code = e;
								});
							}}
							value={state?.code ?? null}
							options={
								codes?.data?.InvoicesCodes?.length
									? codes?.data?.InvoicesCodes.map((item) => {
											return {
												label: item.text,
												value: item.id,
											};
									  })
									: []
							}
						/>
					</Form.Item>
				</Form>
			) : null}

			{info?.status === "pending" && loading ? (
				<div style={{ textAlign: "center", padding: "20px" }}>
					<Spin />
				</div>
			) : null}
		</Modal>
	);
}

export default ModalView;
