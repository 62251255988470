import { Modal, Form, Input, Select, Checkbox, Button, Tabs } from "antd";
import { useImmer } from "use-immer";
import {
	UploadOutlined,
	FileDoneOutlined,
	FilePdfFilled,
} from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { SERVICES } from "scenes/services/Queries";

function ModalView({ visible, refetch, data, id, close }) {
	const [state, setState] = useImmer({});

	const [save, { loading }] = useMutation(gql`
		mutation GlobalPlan($input: GlobalPlanInput!, $id: String) {
			GlobalPlan(input: $input, id: $id)
		}
	`);

	const services = useQuery(
		gql`
		query Services($active_only: Boolean) {
		  Services(active_only: $active_only) {
			 _id,
			 name,
			 price,
			 description,
			 status
		  }
		}
		`,
		{
			fetchPolicy: "no-cache",
			variables: {
				active_only: true,
			},
		}
	);

	useEffect(() => {
		if (visible && data) setState({ ...data });
	}, [visible]);

	const onSubmit = async () => {
		if (!state.name)
			return Modal.error({
				content: "Por favor, informe um nome.",
			});

		if (!state.status)
			return Modal.error({
				content: "Por favor, informe um status.",
			});

		try {
			const { partner_name, ...payload } = state;

			const res = await save({
				variables: {
					input: {
						...payload,
					},
					id: id || null,
				},
			});

			if (res.data.GlobalPlan) {
				close();
				refetch();
				setState({});
			} else {
				Modal.error({
					content: "Não foi possível salvar no momento.",
				});
			}
		} catch (error) {
			Modal.error({
				content: "Não foi possível salvar no momento.",
			});
		}
	};

	return (
		<Modal
			title="Plano"
			visible={visible}
			onCancel={() => {
				close();
				setState({});
			}}
			footer={[
				<Button key="back" onClick={close} disabled={loading}>
					Cancelar
				</Button>,
				<Button
					type="primary"
					loading={loading}
					disabled={loading}
					onClick={onSubmit}
				>
					Enviar
				</Button>,
			]}
		>
			<Tabs>
				<Tabs.TabPane tab="Informações" key="item-1">
					<Form layout="vertical">
						<Form.Item label="Titulo">
							<Input
								value={state.name}
								onChange={(e) => {
									setState((d) => {
										d.name = e.target.value;
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="Descrição">
							<Input.TextArea
								value={state.description}
								onChange={(e) => {
									setState((d) => {
										d.description = e.target.value;
									});
								}}
							/>
						</Form.Item>

						<Form.Item label="Status">
							<Select
								value={state.status}
								onChange={(e) => {
									setState((d) => {
										d.status = e;
									});
								}}
							>
								<Select.Option value="inactive">Inativo</Select.Option>
								<Select.Option value="active">Ativo</Select.Option>
							</Select>
						</Form.Item>
					</Form>
				</Tabs.TabPane>
				<Tabs.TabPane tab="Serviços Disponíveis" key="item-2">
					<Checkbox.Group
						className="vertical-group"
						value={state.available_services}
						onChange={(e) => {
							setState((d) => {
								d.available_services = e;
							});
						}}
						options={
							services?.data?.Services?.length
								? services.data.Services.map((i) => {
										return {
											label: i.name,
											value: i._id,
										};
								  })
								: []
						}
					/>
					<div
						style={{
							padding: 10,
						}}
						className="align-right"
					>
						{services?.data?.Services?.length && (
							<Button
								onClick={() => {
									setState((d) => {
										d.available_services = services.data.Services.map(
											(i) => i._id
										);
									});
								}}
							>
								Selecionar todos
							</Button>
						)}
					</div>
				</Tabs.TabPane>
				<Tabs.TabPane tab="Páginas Disponíveis" key="item-3">
					<Checkbox.Group
						className="vertical-group"
						value={[]}
						onChange={(e) => {
							// setState((d) => {
							// 	d.available_pages = e;
							// });
						}}
						options={[
							{
								label: "Notas Fiscais",
								value: "invoices"
							},
							{
								label: "DAS Parcelamentos",
								value: "das-installment"
							},
							{
								label: "Dívida Ativa",
								value: "active-debit"
							}
						]}
					/>
					
			
				</Tabs.TabPane>
			</Tabs>
		</Modal>
	);
}

export default ModalView;
