import { Layout, Typography, Tabs } from "antd";

import PartnerKeys from "./Components/APIKeys";
import User from "./Components/User";
import Acesses from "./Components/Acessos";
import Contacts from "./Components/Contacts";
import UAU from "./Components/UAU";
import Meetings from "./Components/Meetings";
import NPS from "./Components/NPS";
import Plans from "./Components/Plans";
import Kickoff from "./Components/Kickoff";
import Email from "./Components/Email";
import Web from "./Components/Web";

const { Content } = Layout;
const { Title } = Typography;

function Partner(props) {
	const { id } = props.match.params;

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>{id === "new" ? "Novo Parceiro" : "Editar Parceiro"}</Title>
				</div>

				<Tabs>
					<Tabs.TabPane tab="Informações" key="item-1">
						<User {...props} />
					</Tabs.TabPane>
					{id && id !== "new" ? (
						<>
							<Tabs.TabPane tab="Plano & Contato" key="item-2">
								<Contacts {...props} />
							</Tabs.TabPane>
							<Tabs.TabPane tab="Kickoff" key="item-9">
								<Kickoff {...props} />
							</Tabs.TabPane>
							{process?.env?.REACT_APP_UAU === "1" ? (
								<>
									<Tabs.TabPane tab="Acessos & Pagamento" key="item-3">
										<Acesses {...props} />
									</Tabs.TabPane>
									<Tabs.TabPane tab="Chaves API" key="item-4">
										<PartnerKeys {...props} />
									</Tabs.TabPane>
									<Tabs.TabPane tab="UAU" key="item-5">
										<UAU {...props} />
									</Tabs.TabPane>
									<Tabs.TabPane tab="Reuniões" key="item-6">
										<Meetings {...props} />
									</Tabs.TabPane>
									<Tabs.TabPane tab="NPS" key="item-7">
										<NPS {...props} />
									</Tabs.TabPane>
									<Tabs.TabPane tab="Planos" key="item-8">
										<Plans {...props} />
									</Tabs.TabPane>
									<Tabs.TabPane tab="E-mail" key="item-10">
										<Email {...props} />
									</Tabs.TabPane>
									<Tabs.TabPane tab="Web" key="item-11">
										<Web {...props} />
									</Tabs.TabPane>
								</>
							) : null}
						</>
					) : null}
				</Tabs>
			</Content>
		</Layout>
	);
}

export default Partner;
