import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import { useEffect } from "react";
import {
	Layout,
	Typography,
	Button,
	notification,
	Badge,
	Menu,
	Dropdown,
	Table,
	Modal,
	Form,
	Input,
	Statistic,
	Divider,
	Select,
	Tabs,
	Tooltip,
	Checkbox,
	Empty,
} from "antd";

import dayjs from "dayjs";

import { DownOutlined, MinusOutlined } from "@ant-design/icons";

import InputNumber from "components/InputNumber";
import { useImmer } from "use-immer";
import Swal from "sweetalert2";
import MaskPrice from "functions/mask/price";

import { SERVICES } from "scenes/services/Queries";

const { Content } = Layout;
const { Title } = Typography;

function PlanoModal({ visible, close, refetch, data, partner_id }) {
	const [state, setState] = useImmer({});

	const [saveAction, { loading }] = useMutation(gql`
    mutation PartnerPlan($input: PartnerPlanInput!, $id: String, $partner_id: String!) {
      PartnerPlan(input: $input, id: $id, partner_id: $partner_id)
    }
  `);

	const [loadServices, services] = useLazyQuery(gql`
		query Services($partner_id: String) {
			Services(partner_id: $partner_id) {
				_id,
				name,
				price,
				description,
				status
			}
			}
		`, {
		fetchPolicy: "no-cache",
		variables: {
			partner_id
		}
	});

	const ErrorDialog = (message) => {
		return Swal.fire({
			title: "Erro",
			text: message,
			icon: "error",
		});
	};

	useEffect(() => {
		if (visible && data) {
			console.log(data);
			const { _id, uuid, ...stateData } = data;
			setState(stateData);
			console.log(partner_id);
			loadServices({
				variables: {
					partner_id
				}
			});
		}
	}, [data, visible]);

	const save = async () => {
		if (state.value > 0 && state.installments === null)
			return Modal.error({
				title: "Erro",
				content: "Por favor, informe um número de parcelas",
			});

		if ((state.value > 0 && !state.months) || state.months < 0)
			return Modal.error({
				title: "Erro",
				content: "Por favor, informe um número de meses",
			});

		// if (state.value === null || state.value === undefined)
		// 	return Modal.error({
		// 		title: "Erro",
		// 		content: "Por favor, informe um valor para as parcelas",
		// 	});

		if (!state.status)
			return Modal.error({
				title: "Erro",
				content: "Por favor, informe status",
			});

		try {
			const payload = { ...state };
			payload.months = parseInt(payload.months);
			payload.installments = parseInt(payload.installments);

			if (!payload.value) payload.value = 0;

			let response = await saveAction({
				variables: {
					input: {
						...payload,
					},
					id: data?._id ?? null,
					partner_id,
				},
			});

			if (response?.data?.PartnerPlan) {
				onClose();
				refetch();
				notification.success({
					message: "Plano salvo com sucesso",
				});
			} else {
				ErrorDialog("Não foi possível salvar o plano no momento.");
			}
		} catch (error) {
			ErrorDialog("Não foi possível salvar o plano no momento.");
		}
	};

	const onClose = () => {
		close();
	};

	return (
		<Modal
			afterClose={() => {
				setState({});
			}}
			visible={visible}
			onCancel={() => {
				close();
			}}
			title={data?._id ? "Editar Plano" : "Novo Plano"}
			footer={[
				<Button key="back" onClick={onClose} disabled={loading}>
					Cancelar
				</Button>,
				<Button
					type="primary"
					onClick={save}
					loading={loading}
					disabled={loading}
				>
					Enviar
				</Button>,
			]}
		>
			<Tabs>
				<Tabs.TabPane tab="Dados" key="item-1">
					<Form layout="vertical">
						<Form.Item label="Nome do Plano">
							<Input
								value={state?.name}
								onChange={(e) => {
									setState((d) => {
										d.name = e.target.value;
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="Número de Meses">
							<Input
								type="number"
								value={state?.months}
								onChange={(e) => {
									setState((d) => {
										d.months = e.target.value;
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="Número de Parcelas">
							<Input
								type="number"
								value={state?.installments}
								onChange={(e) => {
									setState((d) => {
										d.installments = e.target.value;
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="Valor Mensal do Plano">
							<InputNumber
								value={state?.value ?? 0}
								onChange={(e) => {
									console.log(e);
									setState((d) => {
										d.value = e;
									});
								}}
							/>
						</Form.Item>

						<Form.Item label="Descrição">
							<Input.TextArea
								value={state?.description}
								onChange={(e) => {
									setState((d) => {
										d.description = e.target.value;
									});
								}}
							/>
						</Form.Item>

						<Form.Item label="Ordem de Exibição" help={'Em ordem crescente.'}>
							<Input
								type="number"
								value={state?.order}
								onChange={(e) => {
									setState((d) => {
										d.order = parseInt(e.target.value);
									});
								}}
							/>
						</Form.Item>

						<Form.Item label="Status">
							<Select
								value={state.status || ""}
								onChange={(e) => {
									setState((draft) => {
										draft.status = e;
									});
								}}
							>
								<Select.Option key={"active"} value={"active"}>
									Ativo
								</Select.Option>
								<Select.Option key={"inactive"} value={"inactive"}>
									Inativo
								</Select.Option>
							</Select>
						</Form.Item>

						<Form.Item label="">
							<Checkbox
								checked={state.show_home}
								onChange={(e) => {
									setState((d) => {
										d.show_home = e.target.checked;
									});
								}}
							>
								Exibir na página inicial
							</Checkbox>
						</Form.Item>
					</Form>

					<Divider />

					{state.installments && state?.value && state?.months ? (
						<Statistic
							title="Valor final a ser cobrado"
							value={`${MaskPrice(state.months * state.value)} por ${
								state.months
							} ${state.months > 1 ? "meses" : "mês"} em ${
								state.installments
							}x `}
						/>
					) : null}
				</Tabs.TabPane>
				<Tabs.TabPane tab="Serviços" key="item-2">
					{state?.services?.length ? (
						state.services.map((item, index) => (
							<Input.Group
								compact
								className="group-handler"
								key={`plan_${index}`}
							>
								<Input
									placeholder="Nome do Serviço"
									value={state.services[index]}
									onChange={(e) => {
										setState((d) => {
											d.services[index] = e.target.value;
										});
									}}
								/>
								<Tooltip title="Remover">
									<Button
										icon={<MinusOutlined />}
										onClick={() => {
											setState((d) => {
												d.services.splice(index, 1);
											});
										}}
									/>
								</Tooltip>
							</Input.Group>
						))
					) : (
						<Empty description="Nenhum serviço cadastrado" />
					)}

					<Divider />
					<div className="align-right">
						<Button
							onClick={() => {
								setState((d) => {
									if (!d.services) {
										d.services = [""];
									} else {
										d.services.push("");
									}
								});
							}}
						>
							Adicionar
						</Button>
					</div>
				</Tabs.TabPane>
				<Tabs.TabPane tab="Serviços Disp." key="item-3">
					<div>Caso nenhum serviço esteja selecionado, todos estarão disponíveis.</div>
					<br></br>
					<Checkbox.Group
						className="vertical-group"
						value={state.available_services}
						onChange={(e) => {
							setState((d) => {
								d.available_services = e;
							});
						}}
						options={
							services?.data?.Services?.length
								? services.data.Services.map((i) => {
										return {
											label: i.name,
											value: i._id,
										};
								  })
								: []
						}
					/>
					<div
						style={{
							padding: 10,
						}}
						className="align-right"
					>
						{services?.data?.Services?.length && (
							<Button
								onClick={() => {
									setState((d) => {
										d.available_services = services.data.Services.map(
											(i) => i._id
										);
									});
								}}
							>
								Selecionar todos
							</Button>
						)}
					</div>
				</Tabs.TabPane>
			</Tabs>
		</Modal>
	);
}

function PartnerPlans(props) {
	const { id } = props.match.params;
	const [modal, setModal] = useImmer({
		visible: false,
	});

	const { data, loading, error, refetch } = useQuery(
		gql`
		query PartnerPlans($partner_id: String!) {
			PartnerPlans(partner_id: $partner_id) {
				uuid
			_id
			status
			name
			installments
			months
			billing_type
			value
			services
			show_home
			description
			available_services
			order
			}
		}
	`,
		{
			variables: {
				partner_id: id,
			},
			fetchPolicy: "no-cache",
		}
	);

	const [createAction, create] = useMutation(
		gql`
		mutation PartnerKey($partner_id: String!) {
			PartnerKey(partner_id: $partner_id)
		}
	`,
		{
			variables: {
				partner_id: id,
			},
			onCompleted: () => {
				refetch();
			},
		}
	);

	const [updateAction, update] = useMutation(
		gql`
		mutation PartnerKeyAction($action: String!, $user_id: String!, $key: String!) {
			PartnerKeyAction(user_id: $user_id, action: $action, key: $key) {
				result
				action
			}
		}
	`,
		{
			onCompleted: (data) => {
				if (data?.PartnerKeyAction.result) {
					// refetch();
					// if (data.PartnerKeyAction.action !== "delete") {
					// 	notification.success({
					// 		message: "Chave atualizada com sucesso",
					// 	});
					// }
					// if (data.PartnerKeyAction.action === "delete") {
					// 	notification.success({
					// 		message: "Chave removida com sucesso",
					// 	});
					// }
				}
			},
		}
	);

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return (
			<Error
				refetch={() => {
					refetch({
						variables: { id },
					});
				}}
			/>
		);
	}


	return (
		<div>
			<div style={{ marginBottom: 20, textAlign: "right" }}>
				<Button
					type="primary"
					onClick={() => {
						setModal({
							visible: true,
						});
					}}
				>
					Novo Plano
				</Button>
			</div>

			<Table
				dataSource={data.PartnerPlans}
				loading={create.loading}
				columns={[
					{
						title: "Nome",
						key: "name",
						dataIndex: "name",
						render: (value) => <code>{value}</code>,
					},
					{
						title: "ID",
						key: "uuid",
						dataIndex: "uuid",
						render: (val) => <code>{val}</code>,
					},
					{
						title: "Parcelas",
						key: "installments",
						dataIndex: "installments",
					},
					{
						title: "Ordem Exib.",
						key: "order",
						dataIndex: "order",
					},
					{
						title: "Meses",
						key: "months",
						dataIndex: "months",
					},
					{
						title: "Valor",
						key: "value",
						dataIndex: "value",
						render: (value) => (value !== null ? MaskPrice(value) : "-"),
					},
					{
						title: "Status",
						dataIndex: "status",
						key: "status",
						width: 100,
						render: (value) => {
							if (value === "active")
								return (
									<>
										<Badge status="success" /> Ativo
									</>
								);
							if (value === "inactive")
								return (
									<>
										<Badge status="error" /> Inativo
									</>
								);
						},
					},
					{
						title: "Criado em",
						key: "createdAt",
						dataIndex: "createdAt",
						width: 200,
						render: (value) => dayjs(value).format("DD/MM/YYYY HH:mm:ss"),
					},
					{
						title: "Ações",
						key: "actions",
						dataIndex: "actions",
						width: 100,
						render: (value, row) => {
							const menu = (
								<Menu>
									<Menu.Item
										onClick={() => {
											setModal({
												visible: true,
												data: row,
											});
										}}
									>
										Editar
									</Menu.Item>
								</Menu>
							);

							return (
								<div style={{ display: "flex" }}>
									<Dropdown
										placement="bottomRight"
										overlay={menu}
										trigger={["click"]}
										arrow
									>
										<Button>
											Ações <DownOutlined />
										</Button>
									</Dropdown>
								</div>
							);
						},
					},
				]}
			/>

			<PlanoModal
				{...modal}
				close={() => {
					setModal({
						visible: false,
					});
				}}
				partner_id={id}
				refetch={refetch}
			/>
		</div>
	);
}

export default PartnerPlans;
