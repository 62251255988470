import { gql } from '@apollo/client';

export const ADMINS = gql`
query {
  Admins {
    _id,
    name,
    email,
    status,
    area,
  }
}
`;


export const ADMIN_SAVE = gql`
mutation Admin($id: String, $input: AdminInput!) {
	Admin(id: $id, input: $input) {
		name
	}
}
`;
