const statuses = {
	0: 'Expirado', 
	1: 'Ativo', 
	2: 'Não cadastrou/requisitou empresa',
	3: 'Aguardando Abertura',
	4: 'Inativo',
	5: 'Cancelado',
};

//
// STATUS
// expired: 0
// active: 1
// need_business: 2
// waiting_business: 3
// inactive: 4
// cancelled: 5
//

export {
	statuses
}