import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
	Layout,
	Typography,
	Button,
	Form,
	Modal,
	Table,
	Select,
	DatePicker,
	Input,
	notification,
} from "antd";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import moment from "moment";

const { Content } = Layout;
const { Title } = Typography;

function Meeting({ visible, close, data, user_id, refetch }) {
	const [state, setState] = useImmer({});

	const [loadAdmins, { data: admins, loading: adminsLoading }] = useLazyQuery(
		gql`
   {
     Admins {
       _id
       name
     }
   }
   `,
		{
			fetchPolicy: "no-cache",
		}
	);

	const [saveAction, { loading: loadingSave }] = useMutation(
		gql`
      mutation PartnerMeeting($user_id: String!, $id: String, $input: PartnerMeeting_Input!) {
         PartnerMeeting(user_id: $user_id, id: $id, input: $input)
      }
   `,
		{
			onCompleted: (data) => {
				notification.success({
					description: "Suas informações foram salvas com sucesso.",
				});
				onClose();
            refetch();
			},
			onError: (err) => {
				Modal.error({
					title: "Erro",
					content: "Não foi possível salvar as informações no momento.",
				});
			},
		}
	);

	useEffect(() => {
		if (visible) loadAdmins();

		if (data) {
			let payload = { ...data };

			delete payload._id;
			delete payload.admin_name;

			if (payload.meeting_date)
				payload.meeting_date = moment(payload.meeting_date);
			if (payload.next_meeting)
				payload.next_meeting = moment(payload.next_meeting);

			setState(payload);
		}
	}, [visible, data]);

	const save = () => {
		let input = { ...state };
		if (input.next_meeting)
			input.next_meeting = input.next_meeting.toISOString();
		if (input.meeting_date)
			input.meeting_date = input.meeting_date.toISOString();


		saveAction({
			variables: {
				input,
				user_id: user_id,
				id: data?._id ? data._id : null,
			},
		});
	};

	const onClose = () => {
		setState({});
		close();
	};

	return (
		<Modal
			title={data?._id ? "Editar Reunião" : "Nova Reunião"}
			visible={visible}
			onOk={() => {}}
			onCancel={onClose}
			footer={[
				<Button key="back" onClick={onClose} disabled={loadingSave}>
					Cancelar
				</Button>,
				<Button
					type="primary"
					loading={loadingSave}
					disabled={loadingSave}
					onClick={save}
				>
					Salvar
				</Button>,
			]}
		>
			<Form layout="vertical">
				<Form.Item label={"Colaborador"}>
					<Select
						onChange={(e) => {
							setState((d) => {
								d.admin = e;
							});
						}}
						value={state?.admin ?? null}
					>
						{admins?.Admins?.length
							? admins.Admins.map((item) => {
									return (
										<Select.Option key={item._id}>{item.name}</Select.Option>
									);
							  })
							: null}
					</Select>
				</Form.Item>
				<Form.Item label={"Data e Horário da Reunião"}>
					<DatePicker
						showTime={{ format: "HH:mm" }}
						format={"DD/MM/YYYY HH:mm"}
						value={state?.meeting_date ?? null}
						style={{ width: "100%" }}
						onChange={(e) => {
							setState((d) => {
								d.meeting_date = e;
							});
						}}
					/>
				</Form.Item>
				<Form.Item label={"Cliente"}>
					<Input
						rows={10}
						value={state?.client ?? null}
						onChange={(e) => {
							setState((d) => {
								d.client = e.target.value;
							});
						}}
					/>
				</Form.Item>
				<Form.Item label={"Pontos tratados"}>
					<Input.TextArea
						rows={10}
						value={state?.content ?? null}
						onChange={(e) => {
							setState((d) => {
								d.content = e.target.value;
							});
						}}
					/>
				</Form.Item>
				<Form.Item label={"Sugestões feitas"}>
					<Input.TextArea
						rows={5}
						value={state?.suggestions ?? null}
						onChange={(e) => {
							setState((d) => {
								d.suggestions = e.target.value;
							});
						}}
					/>
				</Form.Item>
				<Form.Item label={"Próximo contato"}>
					<DatePicker
						showTime={{ format: "HH:mm" }}
						format={"DD/MM/YYYY HH:mm"}
						value={state?.next_meeting ?? null}
						style={{ width: "100%" }}
						onChange={(e) => {
							console.log(e);
							setState((d) => {
								d.next_meeting = e;
							});
						}}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}

function User(props) {
	const { id } = props.match.params;

	const [modal, setModal] = useImmer({
		visible: false,
	});

	const { data, loading, error, refetch } = useQuery(
		gql`
      query PartnerMeetings($user_id: String!) {
         PartnerMeetings(user_id: $user_id) {
            _id
            admin
            admin_name
            meeting_date
            content
            suggestions
            next_meeting
            client
         }
      }
   `,
		{
			variables: {
				user_id: id,
			},
			fetchPolicy: "no-cache",
		}
	);

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return <Error />;
	}

	return (
		<div>
			<div style={{ marginBottom: 20, textAlign: "right" }}>
				<Button
					type="primary"
					onClick={() => {
						setModal({
							visible: true,
							data: {},
						});
					}}
				>
					Adicionar
				</Button>
			</div>
			<Table
				dataSource={data?.PartnerMeetings}
				columns={[
					{
						dataIndex: "meeting_date",
						key: "meeting_date",
						title: "Data da Reunião",
						width: 180,
						render: (value) =>
							value ? moment(value).format("DD/MM/YYYY HH:mm") : "-",
					},
					{
						dataIndex: "admin_name",
						key: "admin_name",
						title: "Colaborador",
						render: (value) => value ?? "-",
					},
					{
						dataIndex: "client",
						key: "client",
						title: "Cliente",
						render: (value) => value ?? "-",
					},
					{
						dataIndex: "next_meeting",
						key: "next_meeting",
						title: "Próximo Contato",
						width: 180,
						render: (value) =>
							value ? moment(value).format("DD/MM/YYYY HH:mm") : "-",
					},
					{
						dataIndex: "actions",
						key: "actions",
						title: "",
						width: 100,
						render: (value, row) => (
							<div>
								<Button
									onClick={() => {
										setModal({
											visible: true,
											data: row,
										});
									}}
								>
									Editar
								</Button>
							</div>
						),
					},
				]}
			/>

			<Meeting
				{...modal}
				user_id={id}
				close={() => {
					setModal({
						visible: false,
						data: {},
					});
				}}
            refetch={refetch}
			/>
		</div>
	);
}

export default User;
