import {
	Layout,
	Typography,
	Row,
	Col,
	Upload,
	Button,
	Form,
	Input,
	Divider,
	Popover,
	notification,
	Modal,
} from "antd";
import { useImmer } from "use-immer";
import { CloudUploadOutlined } from "@ant-design/icons";
import moment from "moment";
import { gql, useMutation, useQuery } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import { FilePdfOutlined } from "@ant-design/icons";
import { SketchPicker, ChromePicker } from "react-color";

const { Content } = Layout;
const { Title } = Typography;

function PartnerPlans(props) {
	const { id } = props.match.params;

	const [state, setState] = useImmer({
		color_primary: "#ccc",
		color_secondary: "#ccc",
	});
	const [logo, setLogo] = useImmer(null);

	const { loading, error } = useQuery(
		gql`
			query PartnerEmailSettings($partner_id: String!) {
			PartnerEmailSettings(partner_id: $partner_id) {
				name
				logo_url
				webapp
				color_primary
				color_secondary
				email
			}
		}
	`,
		{
			variables: {
				partner_id: id,
			},
			onCompleted: (data) => {
				if (data?.PartnerEmailSettings) {
					setState({
						...state,
						...data.PartnerEmailSettings,
						color_primary: data?.PartnerEmailSettings?.color_primary ?? "#ccc",
						color_secondary:
							data?.PartnerEmailSettings?.color_secondary ?? "#ccc",
					});
				}
			},
			fetchPolicy: "no-cache",
		}
	);

	const [saveAction, { loading: loadingSave }] = useMutation(
		gql`
		mutation PartnerEmailSettings($partner_id: String!, $input: PartnerEmailSettingsInput!) {
			PartnerEmailSettings(partner_id: $partner_id, input: $input)
		}
	`,
		{
			onCompleted: (data) => {
				if(data.PartnerEmailSettings) {
					notification.success({
						message: "Configurações salvas com sucesso"
					})
				} else {
					notification.error({
						message: "Erro ao salvar configurações"
					})
				}
			},
			onError: (err) => {
				console.log(err);
				notification.error({
					message: "Erro ao salvar configurações"
				})
			},
		}
	);

	const save = async () => {
		if (!state.name)
			return Modal.error({
				content: "Por favor, informe um nome",
			});
		if (!state.webapp)
			return Modal.error({
				content: "Por favor, informe uma URL para o Webapp",
			});
		if (!state.email)
			return Modal.error({
				content: "Por favor, informe um e-mail válido",
			});

		const { logo_url, ...payload } = state;

		await saveAction({
			variables: {
				input: {
					...payload,
					logo: logo || null,
				},
				partner_id: id,
			},
		});
	};

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return <Error />;
	}


	return (
		<div style={{ paddingTop: 20 }}>
			<Row gutter={40}>
				<Col span={24}>
					<h2 className="text-primary">Configurações de E-mail</h2>
					<Divider />

					<Row gutter={20}>
						<Col span={24}>
							<Row gutter={30}>
								<Col flex={"300px"}>
									<span style={{ paddingBottom: 10, display: "block" }}>
										Logo
									</span>
									<Upload
										className="logo-upload"
										beforeUpload={() => false}
										onChange={(e) => {
											const { file } = e;
											console.log(file);
											setLogo(file);
										}}
										fileList={[]}
										accept="image/png,image/jpg,image/jpeg"
									>
										<div className="image">
											{!logo && state.logo_url ? (
												<>
													<img src={state.logo_url} alt="" />
												</>
											) : null}
											{logo ? (
												<>
													<img src={URL.createObjectURL(logo)} alt="" />
													<div className="upload-icon">
														<CloudUploadOutlined size={20} />
													</div>
												</>
											) : null}
											{!logo && !state.logo_url && (
												<div className="no-logo">
													<CloudUploadOutlined size={30} />
												</div>
											)}
										</div>
									</Upload>
								</Col>
								<Col flex="1">
									<Form layout="vertical">
										<Form.Item label="Nome">
											<Input
												onChange={(e) => {
													setState((d) => {
														d.name = e.target.value;
													});
												}}
												value={state?.name}
											/>
										</Form.Item>

										<Form.Item label="E-mail remetente">
											<Input
												onChange={(e) => {
													setState((d) => {
														d.email = e.target.value;
													});
												}}
												value={state?.email}
											/>
										</Form.Item>

										<Form.Item label="Webapp (sem barra no final)">
											<Input
												onChange={(e) => {
													setState((d) => {
														d.webapp = e.target.value;
													});
												}}
												value={state?.webapp}
											/>
										</Form.Item>

										<Row gutter={20}>
											<Col span={12}>
												<div
													style={{
														display: "flex",
														gap: 10,
													}}
												>
													<Form.Item label="Cor Primária">
														<Popover
															placement="topLeft"
															content={
																<div>
																	<ChromePicker
																		color={state?.color_primary}
																		onChange={(e) => {
																			if (e) {
																				setState((d) => {
																					d.color_primary = e.hex;
																				});
																			}
																		}}
																		disableAlpha
																		presetColors={[]}
																		className="color-wrapper"
																	/>
																</div>
															}
															trigger="click"
														>
															<div className="color-button">
																<i
																	style={{
																		background: `${state?.color_primary}`,
																	}}
																></i>
																<span>{state?.color_primary}</span>
															</div>
														</Popover>
													</Form.Item>
													<Form.Item label="Cor Secundária">
														<Popover
															placement="topLeft"
															content={
																<div>
																	<ChromePicker
																		color={state?.color_secondary}
																		onChange={(e) => {
																			setState((d) => {
																				d.color_secondary = e.hex;
																			});
																		}}
																		disableAlpha
																		presetColors={[]}
																		className="color-wrapper"
																	/>
																</div>
															}
															trigger="click"
														>
															<div className="color-button">
																<i
																	style={{
																		background: `${state?.color_secondary}`,
																	}}
																></i>
																<span>{state?.color_secondary}</span>
															</div>
														</Popover>
													</Form.Item>
												</div>
											</Col>
										</Row>
									</Form>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
			<Divider />
			<div>
				<Button
					onClick={() => {
						save();
					}}
					type="primary"
					loading={loadingSave}
					disabled={loadingSave}
					style={{
						float: "right",
					}}
				>
					Salvar
				</Button>
			</div>
		</div>
	);
}

export default PartnerPlans;
