import { useState, useEffect } from "react";
import * as Queries from "../Queries";
import { useLazyQuery, useMutation, useQuery, gql } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import InputNumber from "components/InputNumber";
import MaskedInput from "antd-mask-input";
import { InboxOutlined } from "@ant-design/icons";
import {
	Col,
	Row,
	Layout,
	Typography,
	Button,
	Form,
	Input,
	Select,
	Popconfirm,
	DatePicker,
	notification,
	Divider,
	Upload,
	Checkbox,
	List,
} from "antd";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import { useDebounce } from "@react-hook/debounce";
import { UploadOutlined } from "@ant-design/icons";
import { useImmer } from "use-immer";

import { parseInt } from "lodash";

const { Dragger } = Upload;

const { Content } = Layout;
const { Title } = Typography;

function User(props) {
	const [state, setState] = useImmer({
		address: {},
	});
	const { id } = props.match.params;
	const [load, { data, loading, error, refetch }] = useLazyQuery(
		Queries.PARTNER_GET,
		{
			fetchPolicy: "no-cache",
			onCompleted: (data) => {
				console.log(data);

				if (data?.Partner) {
					const { user_id, ...payloadData } = data.Partner;

					let payload = { ...payloadData };

					if (payload.date_start) {
						payload.date_start = moment(payload.date_start);
					}
					if (payload.date_end) {
						payload.date_end = moment(payload.date_end);
					}
					if (payload.date_cancel) {
						payload.date_cancel = moment(payload.date_cancel);
					}

					if (!payload.address) payload.address = {};

					setState(payload);
				}
			},
		}
	);
	const [cepLoading, setCepLoading] = useState(false);
	const [partnerId, setPartnerId] = useState(null);
	const [cep, setCep] = useDebounce(null, 500);
	const [saveCompany, { loading: loadingSave }] = useMutation(
		Queries.PARTNER_SAVE
	);

	const [deleteFile] = useMutation(
		gql`
			 mutation PartnerDeleteFile($type: String!, $id: String!,  $user_id: String!) {
				PartnerDeleteFile(type: $type, id: $id,  user_id: $user_id) {
					additives {
						key
						filename
						_id
					}
				}
			 }
		`,
		{
			onCompleted: (data) => {
				console.log(data);

				setState(state => {
					return {
						...state,
						files: {...data.PartnerDeleteFile}
					}
				})

				// load({
				// 	variables: {
				// 		id,
				// 	},
				// });
			}
		}
	);

	const [modalVisible, setModalVisible] = useState(false);

	const globalPlans = useQuery(gql`
		{
			GlobalPlans {
				_id
				name
			}
		}
	`);

	useEffect(() => {
		if (id && id !== "new") {
			load({
				variables: {
					id,
				},
			});
		}
	}, []);

	useEffect(() => {
		async function getCep(cep) {
			let code = cep.replace(/\D/g, "");

			if (code.length === `00000000`.length) {
				setCepLoading(true);
				let response = await axios.get(
					`https://viacep.com.br/ws/${code}/json/`
				);
				setCepLoading(false);

				if (response?.data) {
					setState((draft) => {
						draft.address = {
							...draft.address,
							street: response.data.logradouro,
							neighborhood: response.data.bairro,
							city: response.data.localidade,
							state: response.data.uf,
						};
					});
				} else {
					return ErrorDialog("Não foi possível carregar o CEP");
				}
			}
		}
		if (cep) getCep(cep);
	}, [cep]);

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	const save = async () => {
		if (!state.plan_id)
			return ErrorDialog("Por favor, informe um plano válido.");

		if (!state.partner_type)
			return ErrorDialog("Por favor, informe o tipo de parceiro.");

		const {files, distract, ...payload } = state;
		let input = {...payload };

		if (input.date_start) {
			input.date_start = input.date_start.toISOString();
		}
		if (input.date_end) {
			input.date_end = input.date_end.toISOString();
		}


		if (input?.additives_files?.length) {
			input.additives_files = input.additives_files.map((i) => i.originFileObj);
		}

		try {
			let res = await saveCompany({
				variables: {
					input,
					user_id: id !== "new" ? id : null,
				},
			});

			const { _id: partnerId } = res.data.Partner;
			const payload = res.data.Partner;

			if (payload.date_start) {
				payload.date_start = moment(payload.date_start);
			}
			if (payload.date_end) {
				payload.date_end = moment(payload.date_end);
			}
			if (payload.date_cancel) {
				payload.date_cancel = moment(payload.date_cancel);
			}

			const { user_id, ...response } = payload;

			console.log(response);

			setState(response);

			if (id === "new") {
				notification.success({
					message: "Parceiro salvo com sucesso!",
				});
				props.history.push(`/partner/${partnerId}`);
			} else {
				notification.success({
					message: "Parceiro salvo com sucesso!",
				});
			}
		} catch (e) {
			ErrorDialog("Erro ao salvar o parceiro. Por favor, tente novamente.");
		}
	};

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return (
			<Error
				refetch={() => {
					load({
						variables: { id },
					});
				}}
			/>
		);
	}

	return (
		<Form layout="vertical">
			<br />

			<h2
				style={{
					marginBottom: 30,
				}}
			>
				Dados
			</h2>

			<Row gutter={20}>
				<Col span={12}>
					<Form.Item label="Telefone">
						<MaskedInput
							mask="(11) 1111-1111"
							value={state.phone || null}
							onChange={(e) => {
								setState((draft) => {
									draft.phone = e.target.value;
								});
							}}
						/>
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item label="Celular">
						<MaskedInput
							mask="(11) 11111-1111"
							value={state.mobile || null}
							onChange={(e) => {
								setState((draft) => {
									draft.mobile = e.target.value;
								});
							}}
						/>
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item label="Nome do Contato">
						<Input
							value={state.contact_name || null}
							onChange={(e) => {
								setState((state) => {
									state.contact_name = e.target.value;
								});
							}}
						/>
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item label="Responsável Financeiro">
						<Input
							value={state.financial_accountable || null}
							onChange={(e) => {
								setState((state) => {
									state.financial_accountable = e.target.value;
								});
							}}
						/>
					</Form.Item>
				</Col>

				<Col span={12}>
					<Row gutter={20}>
						<Col span={12}>
							<Form.Item label="Data de Início">
								<DatePicker
									value={state.date_start || null}
									onChange={(d) => {
										setState((draft) => {
											draft.date_start = d;
										});
									}}
									format={"DD/MM/YYYY"}
									style={{ width: "100%" }}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Data Final">
								<DatePicker
									value={state.date_end || null}
									onChange={(d) => {
										setState((draft) => {
											draft.date_end = d;
										});
									}}
									format={"DD/MM/YYYY"}
									style={{ width: "100%" }}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Data de Cancelamento">
								<DatePicker
									value={state.date_cancel || null}
									onChange={(d) => {
										setState((draft) => {
											draft.date_cancel = d;
										});
									}}
									format={"DD/MM/YYYY"}
									style={{ width: "100%" }}
								/>
							</Form.Item>
						</Col>
						<Col span={12}></Col>
						<Col span={12} offset={0}>
							<Form.Item label="">
								<Checkbox
									checked={state.social_posts}
									onChange={(e) => {
										setState((draft) => {
											draft.social_posts = e.target.checked;
										});
									}}
								>
									Posts Redes Sociais
								</Checkbox>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="">
								<Checkbox
									checked={state.tutorials}
									onChange={(e) => {
										setState((draft) => {
											draft.tutorials = e.target.checked;
										});
									}}
								>
									Tutoriais
								</Checkbox>
							</Form.Item>
						</Col>
					</Row>
				</Col>

				<Col span={12}>
					<Row gutter={20}>
						<Col span={12}>
							<Form.Item label="Plano">
								<Select
									value={state.plan_id || null}
									onChange={(value) => {
										setState((draft) => {
											draft.plan_id = value;
										});
									}}
								>
									<Select.Option value={1}>Mensal</Select.Option>
									<Select.Option value={2}>Pré-pago</Select.Option>
									<Select.Option value={3}>Premium</Select.Option>
									<Select.Option value={4}>White Label</Select.Option>
									<Select.Option value={5}>White Label Top</Select.Option>
									<Select.Option value={7}>White Label Light</Select.Option>
									<Select.Option value={8}>White Label Start</Select.Option>
									<Select.Option value={9}>
										White Label Novo Start
									</Select.Option>
									<Select.Option value={6}>Outro</Select.Option>
								</Select>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item label="Plano Parceiro">
								<Select
									value={state.global_plan || null}
									onChange={(value) => {
										setState((draft) => {
											draft.global_plan = value;
										});
									}}
								>
									{!globalPlans.loading &&
									!globalPlans.error &&
									globalPlans?.data?.GlobalPlans?.length
										? globalPlans?.data?.GlobalPlans.map((i) => {
												return (
													<Select.Option value={i._id} key={`plan_${i._id}`}>
														{i.name}
													</Select.Option>
												);
										  })
										: null}
								</Select>
							</Form.Item>
						</Col>

						<Col span={24}>
							<Form.Item label="Tipo de Parceiro">
								<Select
									value={state.partner_type}
									onChange={(value) => {
										console.log(value);
										setState((draft) => {
											draft.partner_type = value;
										});
									}}
								>
									<Select.Option value={"monthly"}>Mensalista</Select.Option>
									<Select.Option value={"pre_paid"}>Pré-pago</Select.Option>
									<Select.Option value={"management"}>Gestão</Select.Option>
									<Select.Option value={"white_label"}>
										White Label
									</Select.Option>
									<Select.Option value={"invoices"}>
										Notas Fiscais
									</Select.Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
				</Col>

				<Col span={12}>
					<Row gutter={20}>
						<Col span={12}>
							<Form.Item label="Num. CNPJs Contratados">
								<Input
									type="number"
									value={state.number_contracted_cnpjs || null}
									onChange={(e) => {
										setState((state) => {
											state.number_contracted_cnpjs = parseInt(e.target.value);
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Valor do Plano">
								<InputNumber
									value={state.plan_value || null}
									onChange={(e) => {
										setState((state) => {
											state.plan_value = e;
										});
									}}
								/>
							</Form.Item>
						</Col>
						
					</Row>
				</Col>
				<Col span={12}>
					<Form.Item label="Condições do Plano">
						<Input.TextArea
							rows={5}
							value={state.plan_conditions || null}
							onChange={(e) => {
								setState((state) => {
									state.plan_conditions = e.target.value;
								});
							}}
						/>
					</Form.Item>
				</Col>
			</Row>

			<h2
				style={{
					marginBottom: 30,
					marginTop: 30,
				}}
			>
				Arquivos
			</h2>

			<Row
				gutter={30}
				style={{
					marginBottom: 60,
				}}
			>
				<Col span={12}>
					<div>
						<h3>Aditivos</h3>

						<Dragger
							multiple
							fileList={state.additives_files || []}
							beforeUpload={() => false}
							accept={".pdf,.jpg,.gif,.png"}
							onChange={(e) => {
								setState((state) => {
									return {
										...state,
										additives_files: e.fileList,
									};
								});
							}}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text" style={{
								fontSize: 13
							}}>
								Clique para selecionar ou arraste arquivos
							</p>
						</Dragger>

						{state.files?.additives?.length ? (
							<>
								<List
									style={{
										marginTop: 20
									}}
									itemLayout="horizontal"
									dataSource={state.files.additives}
									renderItem={(item) => (
										<List.Item
											actions={[
												<Button
													onClick={() => {
														window.open(item.key);
													}}
												>
													Ver
												</Button>,
												<Popconfirm
													title="Tem certeza que deseja remover este arquivo?"
													onConfirm={() => {
														deleteFile({
															variables: {
																type: "additives",
																id: item._id,
																user_id: id
															}
														})
													}}
													onCancel={null}
													okText="Yes"
													cancelText="No"
												>
													<Button danger>Remover</Button>
												</Popconfirm>,
											]}
										>
											<div>{item.filename}</div>
										</List.Item>
									)}
								/>
							</>
						) : null}
					</div>
				</Col>
				<Col span={12}>
					<Form.Item label="Contrato">
						<div
							style={{
								display: "flex",
							}}
						>
							<Upload
								fileList={state.contract_file ? [state.contract_file] : []}
								beforeUpload={() => false}
								maxCount={1}
								accept={".pdf,.jpg,.gif,.png"}
								onChange={(e) => {
									setState((state) => {
										return {
											...state,
											contract_file: e.file,
										};
									});
								}}
							>
								<Button icon={<UploadOutlined />}>Selecionar</Button>
							</Upload>

							{state?.contract ? (
								<Button
									type="primary"
									style={{
										marginLeft: 10,
									}}
									onClick={() => {
										window.open(state.contract);
									}}
								>
									Ver
								</Button>
							) : null}
						</div>
					</Form.Item>
					<Divider />
					<Form.Item label="Distrato">
						<div
							style={{
								display: "flex",
							}}
						>
							<Upload
								fileList={state.distract_file ? [state.distract_file] : []}
								beforeUpload={() => false}
								maxCount={1}
								accept={".pdf,.jpg,.gif,.png"}
								onChange={(e) => {
									setState((state) => {
										return {
											...state,
											distract_file: e.file,
										};
									});
								}}
							>
								<Button icon={<UploadOutlined />}>Selecionar</Button>
							</Upload>
							
							{state?.distract ? (
								<Button
									type="primary"
									style={{
										marginLeft: 10,
									}}
									onClick={() => {
										window.open(state.distract);
									}}
								>
									Ver
								</Button>
							) : null}
						</div>
					</Form.Item>
				</Col>
			</Row>

			<Divider />

			<div>
				<Button
					onClick={save}
					type="primary"
					loading={loadingSave}
					style={{
						marginTop: 20,
						float: "right",
					}}
				>
					Salvar
				</Button>
			</div>
		</Form>
	);
}

export default User;
