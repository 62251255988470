import React, {useState, useEffect, useReducer} from 'react';
import * as Queries from './Queries';
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

import Loading from 'components/Loading/Page';
import Error from 'components/Error/Page';

import MaskPrice from 'functions/mask/price';
import Mask from 'functions/mask';

import { 
	EditOutlined,
	MailOutlined,
	LockOutlined,
	MessageOutlined
} from '@ant-design/icons';


import MaskedInput from "antd-mask-input";
import { Col, Row, Layout, Table, Typography, Button, Select, Form, Input, Popconfirm, notification, Popover, Tooltip} from 'antd';
import {statuses} from './Misc';

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;



function Users(props) {

	  const [, forceUpdate] = useReducer(x => x + 1, 0);
		
	  const [removeCompanyAction] = useMutation(Queries.COMPANY_REMOVE);

	  const [load, { loading, data, error, refetch }] = useLazyQuery(Queries.COMPANIES, {
	  	fetchPolicy: 'no-cache'
	  });

	  useEffect(() => {
	  		load();
	  }, []);


	  const removeCompany = async (row) => {

			let res = await removeCompanyAction({
				variables: {
					id: row._id
				}
			});
			if(res.data.CompanyRemove) {
				notification.success({
					message: 'Empresa removida com sucesso!'
				});
				refetch();
			} else {
				notification.error({
					message: 'Erro ao remover empresa',
					description: 'Por favor, tente novamente mais tarde.'
				})
			}
	  }


	  const columns = [
	    {
	      title: 'Nome',
	      dataIndex: 'company_name',
	      key: 'company_name',
	    },
	    {
	      title: 'CNPJ',
	      dataIndex: 'cnpj',
	      key: 'cnpj',
	    },
	    {
	      title: 'E-mail',
	      dataIndex: 'email',
	      key: 'email',
	    },
	    {
	      title: 'Telefone',
	      dataIndex: 'phone',
	      key: 'phone',
	    },
	    {
	      title: 'Ações',
	      dataIndex: '',
	      key: 'x',
	      width: 200,
	      render: (value, row) => {
	      	return (
				<>
								<Button 
									type="link"
							      	onClick={() => {
							      		props.history.push(`./company/${value._id}`)
							      	}}
						      	>
						      	  Editar
						      	</Button>
						      	<Popconfirm
						      	    title="Deseja remover esta empresa?"
						      	    onConfirm={() => {
						      	    	removeCompany(row)
						      	    }}
						      	    onCancel={null}
						      	    okText="Sim"
						      	    cancelText="Não"
						      	  >
						      	         	<Button type="link">
						      	         	  Remover
						      	         	</Button>
						      	</Popconfirm>
				</>
	      	)
	      },
	    },
	  ];
	

	  return (
	   		  		<Layout className={'page-wrapper'}>
	   			  		<Content className="site-layout-background">

	   			  		  <div className="page-title">
	   			  		  	<Title>Empresas</Title>
	   			  		  	 <Button type="primary" onClick={() => {
	   			  		  	 	props.history.push('/company/new')
	   			  		  	 }}>Nova Empresa</Button>
	   			  		  </div>

	   			  	

	   			  		  <div className="dashboard">

	   			  		  	{loading ? (
	   			  		  		<Loading/>
	   			  		  	) : null}


	   			  		  	{
	   			  		  		(!loading && !error && data) ? (

	   			  		  			<div>
	   			  		  				<Table 
	   			  		  					rowKey="_id" 
	   			  		  					dataSource={data.Companies.length ? data.Companies : []}
	   			  		  					columns={columns} 
	   			  		  				/>
	   			  		  			</div>

	   			  		  		) : null
	   			  		  	}

	   			  		  	{
	   			  		  		(!loading && error) ? (
	   			  		  			<Error
	   			  		  				refetch={() => refetch()}
	   			  		  			/>
	   			  		  		) : null
	   			  		  	}

	   			  		  	
	   			  		  </div>
	   			  		</Content>
	   		  		</Layout>
	  );
}

export default Users;
