import React, { useState, useEffect } from "react";
import * as Queries from "../Queries";
import { useQuery, useMutation } from "@apollo/client";
import Swal from 'sweetalert2';
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import _ from 'lodash';
import MaskedInput from 'antd-mask-input';


import {
	Col,
	Row,
	Layout,
	Table,
	Typography,
	Button,
	Card,
	Form,
	Input,
	Select,
	notification
} from "antd";

import { statuses } from "../Misc";
import Estados from 'functions/states';
import {useDebounce, useDebounceCallback} from '@react-hook/debounce';
import axios from 'axios';
import { useImmer } from "use-immer";

const { Content } = Layout;
const { Title } = Typography;

function User({id}) {
	
	const [disabled, setDisabled] = useState(true);
	const [state, setState] = useImmer(null);

	const [cepLoading, setCepLoading] = useState(false);
	const [cep, setCep] = useDebounce(null, 500);

	const [saveAction, { loading: loadingSave }] = useMutation(Queries.ADDRESS_SAVE);

	const { loading, data, error, refetch } = useQuery(Queries.ADDRESS, {
		fetchPolicy: 'no-cache',
		variables: {
			id
		}
	});

	useEffect(() => {
		if(data) setState(data.AddressByUser || {});
	}, [data]);
	
	const ErrorDialog = (msg) => {
				Swal.fire({
					title: "Erro",
					text: msg,
					type: "error",
					confirmButtonText: "OK"
				});
	}

	const save = async () => {

		if(!state.street) 
			return ErrorDialog('Por favor, insira um endereço válido.');
		if(!state.number) 
			return ErrorDialog('Por favor, insira um número válido.');
		if(!state.city) 
			return ErrorDialog('Por favor, insira uma cidade válida.');
		if(!state.state) 
			return ErrorDialog('Por favor, selecione um estado.');

		let obj = {
			...state
		};

		const {data, error} = await saveAction({
			variables: {
				input: {
					...obj
				},
				id
			}
		});

		if(error) {
			ErrorDialog('Não foi possível salvar as informações no momento. Tente novamente mais tarde.');
		} else {
			notification.success({
				message: 'Sucesso',
				description:
				  'Informações salvas com sucesso',
			});
			refetch();
		}
	}



	useEffect(() => {
		async function getCep(cep) {

			let code = cep.replace(/\D/g, '');

			if(code.length === `00000000`.length) {

				setCepLoading(true);
				let response = await axios.get(`https://viacep.com.br/ws/${code}/json/`);
				setCepLoading(false);

				if(response?.data) {

					setState(state => {
						return {
							...state,
							street: response.data.logradouro,
							neighborhood: response.data.bairro,
							city: response.data.localidade,
							state: response.data.uf,
						}
					});

				} else {
					return ErrorDialog('Não foi possível carregar o CEP');
				}
			}
		}
		if(cep) getCep(cep);
	}, [cep]);


	return (
		<Card title="Endereço Comercial" extra={(<Button onClick={() => setDisabled(state => !state)}>{disabled ? 'Editar' : 'Cancelar'}</Button>)}>

				{
					(loading) ? (
						<Loading/>
					) : null
				}


				{
					(!loading && error) ? (
						<Error refetch={refetch}/>
					) : null
				}
			
				{
					(state && !loading && !error) ? (
						<Form layout="vertical">
							<Row gutter={20}>

								<Col span={6}>
									<Form.Item label="CEP">
										<MaskedInput
											mask={'11111-111'}
 											disabled={disabled}
											value={state?.zip_code || null}
											onChange={(e) => {
												const {value} = e.target;
												setCep(value);
												setState(d => {
													d.zip_code = value;
												})
											}}
											loading={cepLoading}
										/>
									</Form.Item>
								</Col>
								<Col span={10}>
									<Form.Item label="Endereço">
										<Input 
 											disabled={disabled}
											value={state?.street || null}
											onChange={(e) => {
												const {value} = e.target;
												setState(d => {
													d.street = value;
												})
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item label="Número">
										<Input 
 											disabled={disabled}
											value={state?.number || null}
											onChange={(e) => {
												const {value} = e.target;
												setState(d => {
													d.number = value;
												})
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item label="Cidade">
										<Input 
 											disabled={disabled}
											value={state?.city || null}
											onChange={(e) => {
												const {value} = e.target;
												setState(d => {
													d.city = value;
												})
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item label="Bairro">
										<Input 
 											disabled={disabled}
											value={state?.neighborhood || null}
											onChange={(e) => {
												const {value} = e.target;
												setState(d => {
													d.neighborhood = value;
												})
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item label="Complemento">
										<Input 
 											disabled={disabled}
											value={state?.complement || null}
											onChange={(e) => {
												const {value} = e.target;
												setState(d => {
													d.complement = value;
												})
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item label="Estado">
										<Select 
											disabled={disabled}
											value={state?.state || null}
											onChange={(e) => {
												setState(d => {
													d.state = e;
												})
											}}
										>
											{
												Estados('all').map((item, index) => {
													return (
														<Select.Option key={`state_${index}`} value={item.value}>{item.text}</Select.Option>
													);
												})
											}
										</Select>
									</Form.Item>
								</Col>
								
								{
									!disabled ? (
										<Col span={24}>
											<Button 
												type="primary" 
												style={{float: 'right'}}
												loading={loadingSave}
												onClick={save}
											>Salvar</Button>
										</Col>
									) : null
								}

							</Row>
						</Form>
					) : null
				}

		</Card>
	);
}

export default User;
