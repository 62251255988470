import {observable, toJS} from 'mobx';
import _ from 'lodash';
import { gql } from '@apollo/client';

const CHECK = gql`
query AuthCheck($token: String!) {
	AuthCheck(token: $token)
}
`;

class User {
	
	@observable data = null;


	constructor() {

		this.setLogin = async (data) => {
			return new Promise(async (resolve, reject) => {
				try {
					await localStorage.setItem('user', JSON.stringify(data));
					this.data = data;
					resolve(true);
				} catch(e) {
					console.log(e);
					reject(e);
				}
			});
		}

		this.check = async (client) => {

			return new Promise(async (resolve, reject) => {

				try {
					let currentData = localStorage.getItem('user');
					if(!currentData) return reject(false);
					let data = JSON.parse(currentData);

					client.query({
						query: CHECK,
						fetchPolicy: 'no-cache',
						variables: {
							token: data.token
						}
					}).then(result => {

						if(!result.error) {
							if(result?.data?.AuthCheck) {
								this.data = data;
								resolve(true);
							} else {
								reject(false);
							}
						} else {
							reject(false);
						}

						
					});
				} catch(e) {
					reject(false);
				}

			});

		}

		this.logout = () => {

			return new Promise(async (resolve, reject) => {

				try {
					localStorage.removeItem('user');
					resolve(true);
				} catch(e) {
					reject(e);
				}
			});

		}
	 
		this.get = (path) => {
		  return _.get(toJS(this.data), path);
		}
		this.set = (path, value) => {
		  return _.set(this.data, path, value);
		}
	 
	}

}

const user = new User();
export default user;