import React, { useState } from "react";
import * as Queries from "./Queries";
import { useMutation } from "@apollo/client";
import Swal from "sweetalert2";

import {
	Col,
	Row,
	Layout,
	Table,
	Typography,
	Button,
	Card,
	Form,
	Input,
	Select,
} from "antd";
import { useHistory } from "react-router-dom";

import UserInfo from "./components/UserInfo";
import UserDocuments from "./components/UserDocuments";
import Business from "./components/Business";
import Address from "./components/Address";
import Contract from "./components/Contract";
import Documents from "./components/Documents";

const { Content } = Layout;
const { Title } = Typography;

function User(props) {
	const history = useHistory();
	const { id } = props.match.params;
	const [removeAction, { loading }] = useMutation(Queries.USER_REMOVE);


	const remove = () => {
		console.log(id);

		Swal.fire({
			icon: "warning",
			title: "Remover Usuário",
			text: "Tem certeza que deseja remover este usuário? Esta ação é irreversível.",
			showCancelButton: true,
			confirmButtonText: "Sim",
			denyButtonText: `Cancelar`,
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await removeAction({
					variables: {
						id,
					},
				});

				if (res.data.UserRemove) {
					history.push("/users");
				} else {
					Swal.fire({
						icon: "error",
						title: "Erro",
						text: "Não foi possível remover este usuário.",
					});
				}
			}
		});
	};

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>Sobre o usuário</Title>

					<div style={{ display: "flex" }}>
			
						<Button
							type="primary"
							style={{ marginLeft: 10 }}
							onClick={remove}
							danger
						>
							Remover Usuário
						</Button>
					</div>
				</div>

				<UserInfo id={id} />
				<UserDocuments id={id} />
				<Business id={id} />
				<Address id={id} />

				<Row gutter={20}>
					<Col span={12}>
						<Documents id={id} />
					</Col>
					<Col span={12}>
						<Contract id={id} />
					</Col>
				</Row>
			</Content>
		</Layout>
	);
}

export default User;
