import React, {useState, useEffect} from 'react';
import { useQuery, useMutation } from "@apollo/client";
import { Table, Empty, Button, Popconfirm } from 'antd';


function Employee({data, edit, remove}) {

	const getStatus = (status) => {
		if(status === '1') return 'Ativo';
		if(status === '2') return 'Inativo';
	}

	const columns = [
	  {
	    title: 'Nome',
	    dataIndex: 'name',
	    key: 'name',
	  },
	  {
	    title: 'Cargo',
	    dataIndex: 'role',
	    key: 'role',
	  },
	  {
	    title: 'E-mail',
	    dataIndex: 'email',
	    key: 'email',
	    render: (status) => {
	    	return status || ''
	    }
	  },
	  {
	    title: 'CPF',
	    dataIndex: 'cpf',
	    key: 'cpf',
	  },
	  {
	    title: 'Status',
	    dataIndex: 'status',
	    key: 'status',
	    render: (status) => {
	    	return getStatus(status) || ''
	    }
	  },
	  {
	      title: 'Ações',
	      dataIndex: '',
	      key: 'x',
	      width: 200,
	      render: (value, row, index) => {
	      	return (
				<>
								<Button 
									type="link"
							      	onClick={() => {
							      		edit(row, index)
							      	}}
						      	>
						      	  Editar
						      	</Button>

								<Popconfirm
								    title="Deseja remover este usuário?"
								    onConfirm={() => {
								    	remove(row, index)
								    }}
								    onCancel={null}
								    okText="Sim"
								    cancelText="Não"
								  >
								         	<Button type="link">
								         	  Remover
								         	</Button>
								</Popconfirm>

						      	
				</>
	      	)
	      },
	    },
	];

	
	return (
		<Table 
			rowKey="_id" 
			dataSource={data}
			columns={columns}
			locale = {{emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Nenhum funcionário" />}}
		/>
	);
}

export default Employee;
