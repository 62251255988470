import { gql } from "@apollo/client";

export const TICKETS = gql`
  query Tickets($input: TicketInput, $page: Int!) {
    Tickets(input: $input, page: $page) {
      tickets {
        _id
        name
        price
        description
        resolution_date
        status
        user {
          cpf
          name
          _id
        }
        partner {
          _id
          company_name
        }
        admin_id
        service {
          _id
          name
        }
        createdAt
        observation
        unread
      }
      currentPage
      total
      pages
    }
  }
`;

export const TICKET_SAVE = gql`
mutation Ticket($id: String!, $admin: String, $status: String) {
   Ticket(id:$id, admin: $admin, status: $status) {
    _id
  }
}
`;

export const TICKET_OBSERVATION = gql`
  mutation TicketObservation($id: String!, $observation: String) {
    TicketObservation(id: $id, observation: $observation) {
      _id
    }
  }
`;

export const TICKET_REMOVE = gql`
  mutation TicketRemove($id: String!) {
    TicketRemove(id: $id)
  }
`;

export const ADMINS = gql`
  query Admins($status: Int) {
  Admins(status: $status) {
    _id
    name
  }
}
`;
