import React, {useState} from 'react';
import * as Queries from './Queries';
import { useQuery } from "@apollo/client";

import Loading from 'components/Loading/Page';
import Error from 'components/Error/Page';
import MaskPrice from 'functions/mask/price';

import Modal from './Modal';
import { Col, Row, Layout, Table, Typography, Button} from 'antd';

import {statuses} from './Misc';

const { Content } = Layout;
const { Title } = Typography;



function Admins() {

	  const { loading, data, error, refetch } = useQuery(Queries.ADMINS, {
	  	fetchPolicy: 'no-cache'
	  });

	  const [modal, setModal] = useState({
	  	visible: false,
	  	data: null
	  });


	  const columns = [
	    {
	      title: 'Nome',
	      dataIndex: 'name',
	      key: 'name',
	    },
	    {
	      title: 'Email',
	      dataIndex: 'email',
	      key: 'email',
	    },
	    {
	      title: 'Pólo',
	      dataIndex: 'area',
	      key: 'area',
	    },
	    {
	    	title: 'Status',
	    	dataIndex: 'status',
	      	key: 'status',
	      	render: (status) => {
	      		return statuses[parseInt(status)]
	      	}
	    },
	    {
	      title: 'Ações',
	      dataIndex: '',
	      key: 'x',
	      render: (value, row) => {
	      	return <Button type="link"
	      	onClick={() => {
	      		setModal(state => {
	      			return {
	      				visible: true,
	      				data: row
	      			}
	      		});
	      	}}
	      	>
	      	  Editar
	      	</Button>
	      },
	    },
	  ];

	  console.log(data);

	  return (
	   		  		<Layout className={'page-wrapper'}>
	   			  		<Content className="site-layout-background">

	   			  		  <div className="page-title">
	   			  		  	<Title>Administradores</Title>
	   			  		  	 <Button type="primary" onClick={() => {
	   			  		  	 	setModal(state => {
	   			  		  	 		return {
	   			  		  	 			visible: true,
	   			  		  	 			data: {}
	   			  		  	 		}
	   			  		  	 	})
	   			  		  	 }}>Novo Admin</Button>
	   			  		  </div>

	   			  		  <div className="dashboard">

	   			  		  	{loading ? (
	   			  		  		<Loading/>
	   			  		  	) : null}


	   			  		  	{
	   			  		  		(!loading && !error && data) ? (

	   			  		  			<div>
	   			  		  				<Table 
	   			  		  					rowKey="_id" 
	   			  		  					dataSource={data.Admins.length ? data.Admins : []}
	   			  		  					columns={columns} 
	   			  		  				/>
	   			  		  				<Modal
	   			  		  					visible={modal.visible}
	   			  		  					data={modal.data}
	   			  		  					refetch={refetch}
	   			  		  					close={() => {
	   			  		  						setModal(state => {
	   			  		  							return {
	   			  		  								visible: false,
	   			  		  								data: null
	   			  		  							}
	   			  		  						})
	   			  		  					}}
	   			  		  				/>
	   			  		  			</div>

	   			  		  		) : null
	   			  		  	}

	   			  		  	{
	   			  		  		(!loading && error) ? (
	   			  		  			<Error
	   			  		  				refetch={() => refetch()}
	   			  		  			/>
	   			  		  		) : null
	   			  		  	}

	   			  		  	
	   			  		  </div>
	   			  		</Content>
	   		  		</Layout>
	  );
}

export default Admins;
