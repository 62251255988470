import {
	Layout,
	Typography,
	Button,
	Table,
	Tag,
	Popconfirm,
	notification,
	Tooltip,
} from "antd";

import { useImmer } from "use-immer";
import Tutorial from "./Modal";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import { UndoOutlined } from "@ant-design/icons";

import Loading from "components/Loading/Page";
import Error from "components/Error/Page";

const { Content } = Layout;
const { Title } = Typography;

function Tutorials() {
	const [modal, setModal] = useImmer({
		visible: false,
	});

	const [loadingId, setLoadingId] = useImmer(null);

	const { data, loading, refetch, error } = useQuery(gql`
		{
			Checkouts {
				checkout_url
				checkout_status
				checkout_ssl_status
				partner_name
				partner_id
			}
		}
	`);

	const [checkoutAction, checkoutActionState] = useMutation(
		gql`
		mutation Checkout($partner_id: String!, $action: String!) {
			Checkout(partner_id: $partner_id, action: $action) {
				result
				error
			}
		}
	`,
		{
			onCompleted: (data) => {
				setLoadingId(null);
				if (data.Checkout.result) {
					refetch();
					notification.success({
						message: "Checkout atualizado com sucesso.",
					});
				} else {
					notification.error({
						message: "Não foi possível executar a ação no momento.",
					});
				}
			},
			onError: (err) => {
				setLoadingId(null);
				notification.error({
					message: err.message || "Não foi possível executar a ação no momento.",
				});
			},
		}
	);

	const filteredData = (data) => {
		return data;
	};

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>Checkouts</Title>
					<div>
						<Button
							type="primary"
							onClick={() => {
								setModal({ visible: true });
							}}
						>
							Adicionar
						</Button>
					</div>
				</div>

				<div className="dashboard">
					<div>
						{loading ? <Loading /> : null}
						{!loading && error ? <Error refetch={() => refetch()} /> : null}
						{!loading && !error && (
							<Table
								rowKey="_id"
								dataSource={filteredData(data.Checkouts)}
								pagination={{
									hideOnSinglePage: true,
								}}
								columns={[
									{
										key: "checkout_url",
										dataIndex: "checkout_url",
										title: "URL",
										render: (value) => (
											<a href={`https://${value}`} target="_blank">
												{value}
											</a>
										),
									},
									{
										key: "checkout_status",
										dataIndex: "checkout_status",
										title: "Status",
										render: (value) => {
											if (value === "enabled")
												return <Tag color="green">Ativo</Tag>;
											if (value === "disabled")
												return <Tag color="gray">Inativo</Tag>;
										},
									},
									{
										key: "checkout_ssl_status",
										dataIndex: "checkout_ssl_status",
										title: "HTTPS/SSL",
										render: (value, row) => {
											if (value === "pending")
												return (
													<>
														<Tag color="orange">Pendente</Tag>
														<Tooltip title={"Gerar"}>
															<Button
																shape="circle"
																loading={loadingId === row.partner_id}
																icon={<UndoOutlined />}
																onClick={async () => {
																	setLoadingId(row.partner_id);
																	await checkoutAction({
																		variables: {
																			action: "ssl",
																			partner_id: row.partner_id,
																		},
																	});
																}}
															/>
														</Tooltip>
													</>
												);
											if (value === "created")
												return <Tag color="green">Ativo</Tag>;
										},
									},
									{
										key: "partner_name",
										dataIndex: "partner_name",
										title: "Parceiro",
										render: (value, row) => {
											return (
												<Link to={`partner/${row.partner_id}`}>
													{row.partner_name}
												</Link>
											);
										},
									},
									{
										key: "action",
										dataIndex: "action",
										title: "Ações",
										width: 100,
										render: (value, row) => {
											return (
												<div
													style={{
														display: "flex",
														gap: 10,
													}}
												>
													<Popconfirm
														title="Tem certeza que deseja remover?"
														onConfirm={async () => {
															setLoadingId(row.partner_id);
															await checkoutAction({
																variables: {
																	action: "remove",
																	partner_id: row.partner_id,
																},
															});
														}}
														onCancel={null}
														okText="Sim"
														cancelText="Não"
													>
														<Button loading={loadingId === row.partner_id}>
															Remover
														</Button>
													</Popconfirm>
													{row.checkout_status === "disabled" ? (
														<Button
															onClick={async () => {
																setLoadingId(row.partner_id);
																await checkoutAction({
																	variables: {
																		action: "enable",
																		partner_id: row.partner_id,
																	},
																});
															}}
															loading={loadingId === row.partner_id}
														>
															Ativar
														</Button>
													) : (
														<Popconfirm
															title="Tem certeza que deseja desativar?"
															onConfirm={async () => {
																setLoadingId(row.partner_id);
																await checkoutAction({
																	variables: {
																		action: "disable",
																		partner_id: row.partner_id,
																	},
																});
															}}
															onCancel={null}
															okText="Sim"
															cancelText="Não"
															
														>
															<Button loading={loadingId === row.partner_id}>Desativar</Button>
														</Popconfirm>
													)}
												</div>
											);
										},
									},
								]}
							/>
						)}
					</div>

					<Tutorial
						{...modal}
						refetch={refetch}
						close={() => {
							setModal({ visible: false });
						}}
					/>
				</div>
			</Content>
		</Layout>
	);
}

export default Tutorials;
