import { gql } from '@apollo/client';

export const SEARCH = gql`
  query UserSearch($term: String!) {
    UserSearch(term: $term) {
      	_id
		name
    }
  }
`;
