import React, { useEffect } from "react";
import {
	Modal,
	Form,
	Input,
	notification,
	Upload,
	Button,
	Select,
	Alert,
	Spin,
} from "antd";
import InputNumberCurrency from "components/InputNumber";
import { useImmer } from "use-immer";
import { useMutation, useLazyQuery, gql } from "@apollo/client";
import { INVOICE_REQUEST } from "./Queries";
import Swal from "sweetalert2";
import {
	UploadOutlined,
	FileDoneOutlined,
	FilePdfFilled,
} from "@ant-design/icons";
import UserSearch from "components/UserSearch";
import MaskedInputCPFCNPJ from "components/InputCPFCNPJ";
import MaskedInput from "antd-mask-input";
import PartnerSearch from "components/PartnerSearch";
import axios from "axios";
import { useDebounce } from "@react-hook/debounce";

const { TextArea } = Input;

const ServiceModal = ({ visible, data, close, refetch }) => {
	const [state, setState] = useImmer({
		invoice_type: "service",
	});

	const [cepInfo, setCepInfo] = useImmer({});

	const [cep, setCep] = useDebounce(null, 500);
	const [save, { loading }] = useMutation(gql`
		mutation InvoiceImport($input: InvoiceImportInput!) {
			InvoiceImport(input: $input) {
				errored
				result
			}
		}
	`);

	const ErrorDialog = (message) => {
		return Swal.fire({
			title: "Erro",
			text: message,
			icon: "error",
		});
	};

	const [loadCTN, ctn] = useLazyQuery(
		gql`
		{
			InvoiceCTN {
				id
				text
			}
		},
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	const onClose = () => {
		setState({});
		close();
	};

	useEffect(() => {
		if (data) {
			setState({
				...data,
			});
		}
		if (visible) {
			loadCTN();
		}
	}, [visible]);

	useEffect(() => {
		async function getCep(cep) {
			let code = cep.replace(/\D/g, "");

			if (code.length === `00000000`.length) {
				let response = await axios.get(
					`https://viacep.com.br/ws/${code}/json/`
				);

				if (response?.data) {
					setCepInfo({
						street: response.data.logradouro,
						neighborhood: response.data.bairro,
						city: response.data.localidade,
						state: response.data.uf,
					});
				} else {
					setState(d => {
						d.zip_code = null;
					})
					return ErrorDialog("Não foi possível carregar o CEP");
				}
			}
		}
		if (cep) getCep(cep);
	}, [cep]);

	const proceed = async () => {
		if (!state.invoice_type)
			return ErrorDialog("Por favor, selecione um tipo de nota.");
		if (!state.partner_id)
			return ErrorDialog("Por favor, informe um parceiro.");
		if (!state.zip_code)
			return ErrorDialog("Por favor, informe um CEP.");
		if (!state.file) return ErrorDialog("Por favor, selecione um arquivo.");

		if (state.invoice_type === "service" && !state.service_code)
			return ErrorDialog(
				"Por favor, informe um Código de Tributação Nacional."
			);

		try {
			const {
				_id,
				business_id,
				createdAt,
				business_name,
				finishedAt,
				fileURL,
				job_uuid,
				user_name,
				partner_company,
				...input
			} = state;

			let vars = {
				input: {
					...input,
				},
				id: _id || null,
			};

			let res = await save({
				variables: vars,
			});

			if (res?.data?.InvoiceImport?.result) {
				notification.success({
					message: "Sucesso",
					description: "Nota Fiscais importadas com sucesso!",
				});

				if(res?.data?.InvoiceImport?.errored?.length) {
					Modal.info({
						title: "Usuários com erro na importação",
						content: (
							<div style={{
								whiteSpace: "pre"
							}}>
								{res?.data?.InvoiceImport?.errored.join("\r\n")}
							</div>
						)
					})
				}

				close();
				setState({});
				refetch();
			} else {
				ErrorDialog("Não foi possível enviar a solicitação no momento.");
			}
		} catch (error) {
			return ErrorDialog(
				error?.message || "Não foi possível enviar a solicitação no momento."
			);
		}
	};


	return (
		<Modal
			title="Importar Nota Fiscal"
			visible={visible}
			onOk={proceed}
			onCancel={() => {
				if (!loading) onClose();
			}}
			footer={[
				<Button key="back" onClick={onClose} disabled={loading}>
					Cancelar
				</Button>,
				<Button
					type="primary"
					loading={loading}
					disabled={loading}
					onClick={proceed}
				>
					Salvar
				</Button>,
			]}
		>
			<Form layout={"vertical"}>
				<Form.Item label="Parceiro">
					<PartnerSearch
						value={state?.partner_id || null}
						onChange={(e) => {
							setState((d) => {
								d.partner_id = e;
							});
						}}
					/>
				</Form.Item>

				{visible && (
					<Form.Item label="CPF / CNPJ Tomador">
						<MaskedInputCPFCNPJ
							value={state?.cpfcnpj || null}
							onChange={(e) => {
								const { value } = e.target;
								setState((d) => {
									d.cpfcnpj = value;
								});
							}}
						/>
					</Form.Item>
				)}

				<Form.Item label="CEP do tomador">
					<MaskedInput
						mask={"11111-111"}
						value={state.address?.zip_code || null}
						onChange={(e) => {
							const { value } = e.target;
							setState(d => {
								d.zip_code = value;
							})
							setCep(value);
						}}
					/>
				</Form.Item>

				{cepInfo && Object.keys(cepInfo).length ? (
					<div style={{
						padding: 10,
						border: '1px solid #e5e5e5',
						background: "#f1f1f1",
						marginBottom: 20
					}}>
						
						{cepInfo.street} <br />
						{cepInfo.neighborhood} <br />
						{cepInfo.city} /	{cepInfo.state}  <br />
					</div>
				) : null}

				{state?.invoice_type === "service" ? (
					<Form.Item label="Código de Tributação Nacional">
						{ctn?.loading ? (
							<Spin />
						) : (
							<Select
								showSearch
								filterOption={(inputValue, option) => {
									return (
										option.label
											.toLowerCase()
											.indexOf(inputValue.toLowerCase()) > -1
									);
								}}
								onChange={(e) => {
									setState((d) => {
										d.service_code = e;
									});
								}}
								value={state?.service_code || null}
								options={
									ctn?.data?.InvoiceCTN?.length
										? ctn?.data?.InvoiceCTN.map((item) => {
												return {
													label: item.text,
													value: item.id,
												};
										  })
										: []
								}
							/>
						)}
					</Form.Item>
				) : null}

				<Form.Item label="Descrição">
					<TextArea
						rows={8}
						value={state.description || null}
						onChange={(e) => {
							const { value } = e.target;
							setState((d) => {
								d.description = value;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label="Observações">
					<TextArea
						rows={4}
						value={state.notes || null}
						onChange={(e) => {
							const { value } = e.target;
							setState((d) => {
								d.notes = value;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label={"Arquivo"}>
					<Upload
						maxCount={1}
						accept={".csv"}
						beforeUpload={() => false}
						onChange={(e) => {
							setState((d) => {
								d.file = e.file;
							});
						}}
					>
						<Button icon={<UploadOutlined />}>Selecionar arquivo</Button>
					</Upload>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default ServiceModal;
