import { useState, useEffect } from "react";
import * as Queries from "../Queries";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import InputNumber from "components/InputNumber";
import MaskedInput from "antd-mask-input";
import {
	Col,
	Row,
	Layout,
	Typography,
	Button,
	Form,
	Input,
	Select,
	DatePicker,
	notification,
	Divider,
	Upload,
	Modal,
} from "antd";
import Estados from "functions/states";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import { useDebounce } from "@react-hook/debounce";
import { UploadOutlined } from "@ant-design/icons";
import { useImmer } from "use-immer";

import { InfoCircleOutlined } from "@ant-design/icons";
import { parseInt } from "lodash";

const { Content } = Layout;
const { Title } = Typography;

function User(props) {
	const { id } = props.match.params;

	const { data, loading, error } = useQuery(
		gql`
		query PartnerUAU($user_id: String!) {
			PartnerUAU(user_id: $user_id) {
				contact_form
				contact_profile
				mei_knowledge
				clients_number
				clients_number_3months
				price
				mei_prospection
				mei_prospection_other
				commercial_area
				commercial_area_employees
				commercial_niche
				commercial_niche_which
				clients_onboarding
				liked_points
				liked_points_other
				frequent_needs
				frequent_needs_other
			}
		}
	`,
		{
			variables: {
				user_id: id,
			},
			fetchPolicy: "no-cache",
			onCompleted: (data) => {
				if(data?.PartnerUAU) {
					setState(data.PartnerUAU)
				}
				
			},
		}
	);

	const [saveAction] = useMutation(
		gql`
		mutation PartnerUAU($user_id: String!, $input: PartnerUAU_Input) {
			PartnerUAU(user_id: $user_id, input: $input)
		}
	`,
		{
			onCompleted: (data) => {
				Modal.success({
					title: "Informações atualizadas com sucesso",
				});
			},
			onError: (err) => {
				Modal.error({
					title: "Erro",
					content: "Não foi possível atualizar as informações.",
				});
			},
		}
	);

	const [state, setState] = useImmer({});

	const save = async () => {
		await saveAction({
			variables: {
				input: {
					...state,
				},
				user_id: id,
			},
		});
	};

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return <Error />;
	}

	return (
		<Form layout="vertical">
			<br />

			<Row gutter={20}>
				<Col span={8}>
					<Form.Item label="Melhor Forma de Contato">
						<Select
							value={state?.contact_form || null}
							onChange={(value) => {
								setState((draft) => {
									draft.contact_form = value;
								});
							}}
						>
							{Array.from(["WhatsApp", "E-mail", "Telefone"]).map((item) => {
								return <Select.Option value={item}>{item}</Select.Option>;
							})}
						</Select>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Perfil da Pessoa de Contato">
						<Select
							value={state?.contact_profile || null}
							onChange={(value) => {
								setState((draft) => {
									draft.contact_profile = value;
								});
							}}
						>
							{Array.from([
								"Descontraído",
								"Amigável",
								"Sério",
								"Objetivo/Direto",
								"Desconfiado",
							]).map((item) => {
								return <Select.Option value={item}>{item}</Select.Option>;
							})}
						</Select>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Conhecimento sobre regras do MEI">
						<Select
							value={state?.mei_knowledge || null}
							onChange={(value) => {
								setState((draft) => {
									draft.mei_knowledge = value;
								});
							}}
						>
							{Array.from(["Básico", "Intermediário", "Avançado"]).map(
								(item) => {
									return <Select.Option value={item}>{item}</Select.Option>;
								}
							)}
						</Select>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Núm. Clientes Recorrentes">
						<Input
							type="number"
							value={state?.clients_number || null}
							onChange={(e) => {
								setState((draft) => {
									draft.clients_number = parseInt(e.target.value);
								});
							}}
						/>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Quantos clientes conquistou nos últimos 3 meses?">
						<Input
							type="number"
							value={state?.clients_number_3months || null}
							onChange={(e) => {
								setState((draft) => {
									draft.clients_number_3months = parseInt(e.target.value);
								});
							}}
						/>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Valor Cobrado">
						<InputNumber
							value={state?.price || null}
							onChange={(value) => {
								setState((draft) => {
									draft.price = value;
								});
							}}
						/>
					</Form.Item>
				</Col>
				<Col span={state?.mei_prospection?.indexOf("Outros") > -1 ? 18 : 24}>
					<Form.Item label="Como prospecta MEI">
						<Select
							value={state?.mei_prospection || []}
							mode="multiple"
							onChange={(value) => {
								setState((draft) => {
									draft.mei_prospection = value;
								});
							}}
						>
							{Array.from([
								"Redes Sociais",
								"Google",
								"Facebook",
								"Eventos",
								"Grupos",
								"Demanda Passiva",
								"Outros",
							]).map((item) => {
								return <Select.Option value={item}>{item}</Select.Option>;
							})}
						</Select>
					</Form.Item>
				</Col>
				{state?.mei_prospection?.indexOf("Outros") > -1 ? (
					<Col span={6}>
						<Form.Item label="">
							<Input
								placeholder="Outros..."
								style={{ marginTop: 30 }}
								value={state?.mei_prospection_other || null}
								onChange={(e) => {
									setState((draft) => {
										draft.mei_prospection_other = e.target.value;
									});
								}}
							/>
						</Form.Item>
					</Col>
				) : null}

				<Col span={8}>
					<Form.Item label="Possui área comercial?">
						<Select
							value={state?.commercial_area || null}
							onChange={(value) => {
								setState((draft) => {
									draft.commercial_area = value;
								});
							}}
						>
							{Array.from(["Sim", "Não"]).map((item) => {
								return <Select.Option value={item}>{item}</Select.Option>;
							})}
						</Select>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Possui foco em determinado nicho?">
						<div style={{ display: "flex", gap: 10 }}>
							<Select 
								value={state?.commercial_niche || null}
								onChange={(value) => {
									setState((draft) => {
										draft.commercial_niche = value;
									});
								}}
							>
								{Array.from(["Sim", "Não"]).map((item) => {
									return <Select.Option value={item}>{item}</Select.Option>;
								})}
							</Select>
							{state?.commercial_niche === "Sim" ? (
								<Input
									placeholder="Qual?"
									value={state.commercial_niche_which || null}
									onChange={(e) => {
										setState((draft) => {
											draft.commercial_niche_which = e.target.value;
										});
									}}
								/>
							) : null}
						</div>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Realiza Onboarding com seus clientes?">
						<div style={{ display: "flex", gap: 10 }}>
							<Select
								value={state?.clients_onboarding || null}
								onChange={(value) => {
									setState((draft) => {
										draft.clients_onboarding = value;
									});
								}}
							>
								{Array.from(["Sim", "Não"]).map((item) => {
									return <Select.Option value={item}>{item}</Select.Option>;
								})}
							</Select>
						</div>
					</Form.Item>
				</Col>
				<Col span={state?.liked_points?.indexOf("Outros") > -1 ? 18 : 24}>
					<Form.Item label={`Pontos que mais gosta`}>
						<Select
							value={state?.liked_points || []}
							mode="multiple"
							onChange={(value) => {
								setState((draft) => {
									draft.liked_points = value;
								});
							}}
						>
							{Array.from([
								"Atendimento",
								"Plataforma",
								"App",
								"Serviços de Marketing (Site, Posts)",
								"Serviço Operacional",
								"Outros",
							]).map((item) => {
								return <Select.Option value={item}>{item}</Select.Option>;
							})}
						</Select>
					</Form.Item>
				</Col>
				{state?.liked_points?.indexOf("Outros") > -1 ? (
					<Col span={6}>
						<Form.Item label="">
							<Input
								placeholder="Outros..."
								style={{ marginTop: 30 }}
								value={state?.liked_points_other || null}
								onChange={(e) => {
									setState((draft) => {
										draft.liked_points_other = e.target.value;
									});
								}}
							/>
						</Form.Item>
					</Col>
				) : null}
				<Col span={state?.frequent_needs?.indexOf("Outros") > -1 ? 18 : 24}>
					<Form.Item label={`Necessidades frequentes`}>
						<Select
							value={state?.frequent_needs || []}
							mode="multiple"
							onChange={(value) => {
								setState((draft) => {
									draft.frequent_needs = value;
								});
							}}
						>
							{Array.from([
								"Atendimento",
								"Plataforma",
								"App",
								"Serviços de Marketing (Site, Posts)",
								"Serviço Operacional",
								"Outros",
							]).map((item) => {
								return <Select.Option value={item}>{item}</Select.Option>;
							})}
						</Select>
					</Form.Item>
				</Col>
				{state?.frequent_needs?.indexOf("Outros") > -1 ? (
					<Col span={6}>
						<Form.Item label="">
							<Input
								placeholder="Outros..."
								style={{ marginTop: 30 }}
								value={state?.frequent_needs_other || null}
								onChange={(e) => {
									setState((draft) => {
										draft.frequent_needs_other = e.target.value;
									});
								}}
							/>
						</Form.Item>
					</Col>
				) : null}
			</Row>

			<Divider />

			<div>
				<Button
					onClick={save}
					type="primary"
					//loading={loadingSave}
					style={{
						marginTop: 20,
						float: "right",
					}}
				>
					Salvar
				</Button>
			</div>
		</Form>
	);
}

export default User;
