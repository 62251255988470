import React, {useState, useEffect} from 'react';
import * as Queries from './Queries';
import { useQuery, useMutation } from "@apollo/client";
import Swal from 'sweetalert2';
import Loading from 'components/Loading/Page';
import Error from 'components/Error/Page';

import { Col, Row, Layout, Table, Typography, Button, Card, Form, Input, Select, notification, List, Comment, Skeleton, Descriptions, Divider} from 'antd';
import moment from 'moment';

const { Content } = Layout;
const { Title } = Typography;
const { TextArea } = Input;



function User(props) {
	
	const {id: user_id} = props.match.params;

	const [state, setState] = useState({});

	const [saveAction, { loading: loadingSave }] = useMutation(Queries.LOGS_SAVE);

	const {data, error, loading, refetch} = useQuery(Queries.LOGS_GET, {
		variables: {
			id: user_id
		},
		fetchPolicy: 'no-cache'
	});

	const { loading: loadingInfo, data: dataInfo, error: errorInfo, refetch: refetchInfo } = useQuery(Queries.USER, {
		fetchPolicy: 'no-cache',
		variables: {
			id: user_id
		}
	});
	
	const ErrorDialog = (msg) => {
				Swal.fire({
					title: "Erro",
					text: msg,
					type: "error",
					confirmButtonText: "OK"
				});
	}

	const save = async () => {
		
		const {content} = state;

		if(!content) return ErrorDialog('Por favor, informe uma descrição para o registro.')

		let input = {
			content,
			user_id
		};
		
		try {
			let response = await saveAction({
				variables: {
					input
				}
			});

			if(response?.data?.UsersLog) {
				notification.success({
					message: 'Sucesso',
					description:
					  'Informações salvas com sucesso',
				});
				setState({});
				refetch({
					fetchPolicy: 'no-cache',
					variables: {input}
				})
			} else {
				ErrorDialog('Não foi possível salvar as informações no momento. Tente novamente mais tarde.');
			}
		} catch(e) {
			ErrorDialog('Não foi possível salvar as informações no momento. Tente novamente mais tarde.');
		}


	}

	console.log(dataInfo);
	
	const {id} = props.match.params;

	  return (
	   		  		<Layout className={'page-wrapper'}>
	   			  		<Content className="site-layout-background">

	   			  		 	<div className="page-title">
	   			  		 		<Title>Atendimento</Title>
	   			  		 	</div>
							
							{
								(loadingInfo && !errorInfo) ? (
									<Skeleton active />
								) : null
							}

							{
								(!loadingInfo && !errorInfo) ? (
									<div>
										<Descriptions title="Informações do Usuário">
										  <Descriptions.Item label="Nome:">{dataInfo?.User.name}</Descriptions.Item>
										  <Descriptions.Item label="CPF">{dataInfo?.User.cpf}</Descriptions.Item>
										  <Descriptions.Item label="Telefone">{dataInfo?.User.phone}</Descriptions.Item>
										</Descriptions>
										<Divider />
									</div>
								) : null
							}

							{
								errorInfo ? (
									<div>
										<Error refetch={refetchInfo}/>
									</div>
								) : null
							}


	   			  		 	<Row gutter={40}>
	   			  		 		<Col span={12}>
	   			  		 		   		<h2>Registros</h2>
		
										{
											(loading) ? (
												<div>
													<Skeleton active />
												</div>
											) : null
										}

										{
											(!loading && error) ? (
												<Error refetch={refetch}/>
											) : null
										}

										{
											(!loading && data?.UsersLogs?.length) ? (
												<List
												  className="comment-list"
												  header={`${data.UsersLogs.length} registros`}
												  itemLayout="horizontal"
												  dataSource={data.UsersLogs}
												  renderItem={item => (
												    <li>
												      <Comment
												        author={item.author}
												        avatar={(<img src="/avatar.png" alt=""/>)}
												        content={item.content}
												        datetime={moment(item.createdAt).format('DD/MM/YYYY - hh:mm:ss')}
												      />
												    </li>
												  )}
												/>
											) : null
										}

										{
											(!loading && !data?.UsersLogs?.length) ? (
												<div>Nenhum registro encontado.</div>
											) : null
										}



	   			  		 		</Col>
	   			  		 		<Col span={12}>

										<h2>Novo Registro</h2>

	   			  		 		   		<TextArea 
	   			  		 		   			rows={4}
	   			  		 		   			value={state.content || null}
	   			  		 		   			onChange={(e) => {
	   			  		 		   				const {value: content} = e.target;
	   			  		 		   				setState(state => {
	   			  		 		   					return {
	   			  		 		   						...state,
	   			  		 		   						content
	   			  		 		   					}
	   			  		 		   				})
	   			  		 		   			}}
	   			  		 		   			placeholder={'Descrição'}
	   			  		 		   			style={{
	   			  		 		   				marginBottom: 20,
	   			  		 		   			}}
	   			  		 		   		/>
										<Button 
											type="primary" 
											style={{float: 'right'}} 
											onClick={save}
											loading={loadingSave}
										>Salvar</Button>

	   			  		 		</Col>
	   			  		 	</Row>
	   			  		
	   			  		</Content>
	   		  		</Layout>
	  );
}

export default User;
