import React from 'react';
import { Button, Result } from 'antd';

function NotFound(props) {
	return (
		<Result
		status="404"
		title="Ops!"
		subTitle="A página ou conteúdo que você procura não existe."
	 />
	)
}

export default NotFound;