import React, {useState, useEffect} from 'react';
import * as Queries from './Queries';
import { useLazyQuery, useMutation } from "@apollo/client";

import Loading from 'components/Loading/Page';
import Error from 'components/Error/Page';
import MaskPrice from 'functions/mask/price';
import _ from 'lodash';
import MaskedInput from 'antd-mask-input';
import { Col, Row, Layout, Table, Typography, Button, Card, Form, Input, Select, DatePicker, Upload, notification, Divider } from 'antd';
import Estados from 'functions/states';
import moment from 'moment';
import Swal from 'sweetalert2';
import isEmail from 'validator/lib/isEmail';
import {statuses} from './Misc';
import {useDebounce, useDebounceCallback} from '@react-hook/debounce';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import InputPhone from 'components/Inputs/Phone';

import EmployeeTable from './components/employee-table';
import EmployeeModal from './components/employee';
import { useImmer } from "use-immer";

const { Content } = Layout;
const { Title } = Typography;
const { TextArea } = Input;



function User(props) {
  
  const {id} = props.match.params;

  const [load, { data, loading, error, refetch }] = useLazyQuery(Queries.COMPANY_GET, {
  	fetchPolicy: 'no-cache'
  });

  const [removeEmployeeAction] = useMutation(Queries.EMPLOYEE_REMOVE);
  const [saveCompany, {loading: loadingSave}] = useMutation(Queries.COMPANY_SAVE);
	
  const [modalVisible, setModalVisible] = useState(false);

  const [modalData, setModalData] = useState(null);
  const [modalDataIndex, setModalDataIndex] = useState(null);


  useEffect(() => {
  		if(id && id !== 'new') {
  			load({
				variables: {
					id
				}
			});
  		}
  }, []);

  useEffect(() => {
		if(data?.Company) {
			let payload = {...data.Company};
			if(payload.date_start) {
				payload.date_start = moment(payload.date_start);
			}
			if(payload.date_end) {
				payload.date_end = moment(payload.date_end);
			}
			setState(payload);
		}
  }, [data]);

  const [state, setState] = useImmer({
  	employees:[]
  });

  const ErrorDialog = (msg) => {
  	Swal.fire({
  		title: "Erro",
  		text: msg,
  		type: "error",
  		confirmButtonText: "OK"
  	});
  }

  const removeEmployee = async (data, index) => {
  	if(data._id) {
		let res = await removeEmployeeAction({
			variables: {
				id: data._id
			}
		});
		if(res.data.CompanyEmployeeRemove) {
			refetch();
		} else {
			ErrorDialog('Não foi possível remover o funcionário no momento. Tente novamente mais tarde.');
		}
  	} else {
  		setState(draft => {
  			draft.employees = draft.employees.splice(index, 1);
  		})
  	}
  }

  const editEmployee = (data, index) => {
  		setModalData(data);
  		setModalDataIndex(index);
  		setModalVisible(true);
  }



  const save = async () => {

		if(!state.company_name)
			return ErrorDialog('Por favor, informe uma razão social válida.');
		// if(!state.trade_name)
		// 	return ErrorDialog('Por favor, informe um nome fantasia válido.');
		// if(!state.cnpj)
		// 	return ErrorDialog('Por favor, informe um CNPJ válido.');
		// if(!state.phone)
		// 	return ErrorDialog('Por favor, informe um telefone válido.');
		// if(!state.date_start)
		// 	return ErrorDialog('Por favor, informe uma data de início.');
		// if(!state.date_end)
		// 	return ErrorDialog('Por favor, informe uma data final.');


		let input = {...state};
		if(input.date_start) {
			input.date_start = input.date_start.toISOString();
		}
		if(input.date_end) {
			input.date_end = input.date_end.toISOString();
		}

		
		try {
			let res = await saveCompany({
				variables: {input}
			});

			const {_id: companyId} = res.data.Company;
			const payload = res.data.Company;

			if(payload.date_start) {
				payload.date_start = moment(payload.date_start);
			}
			if(payload.date_end) {
				payload.date_end = moment(payload.date_end);
			}

			setState(payload);


			if(id === 'new') {
				notification.success({
					message: 'Empresa salva com sucesso!'
				})
				props.history.push(`/company/${companyId}`);
			} else {
				notification.success({
					message: 'Empresa salva com sucesso!'
				})
			}

			

		} catch(e) {
			ErrorDialog('Erro ao salvar a empresa. Por favor, tente novamente.');
		}

  }

  if(loading) {
  		return (
			<Layout className={'page-wrapper'}>
				<Content className="site-layout-background">

					<div className="page-title">
						<Title>{'Editar Empresa'}</Title>
					</div>
					<Loading/>
				</Content>
			</Layout>
  		)
  }
	
  if(error) {
  		return (
			<Layout className={'page-wrapper'}>
				<Content className="site-layout-background">

					<div className="page-title">
						<Title>{'Editar Empresa'}</Title>
					</div>
					
					<Error
						refetch={() => {
							load({
								variables: {id}
							})
						}}
					/>

				</Content>
			</Layout>
  		)
  }

  return (
		<Layout className={'page-wrapper'}>
			<Content className="site-layout-background">

			<div className="page-title">
				<Title>{id === 'new' ? 'Nova Empresa' : 'Editar Empresa'}</Title>
			</div>

				
			<Form layout="vertical">

					<Title level={3}>Informações Básicas</Title>
		 
				<Row gutter={20}>
					<Col span={12}>
						<Form.Item label="Razão Social">
							<Input 
								value={state.company_name || null}
								onChange={(e) => {
									setState(draft => {
										draft.company_name = e.target.value
									})
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label="Nome Fantasia">
							<Input 
								value={state.trade_name || null}
								onChange={(e) => {
									setState(draft => {
										draft.trade_name = e.target.value
									})
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label="CNPJ">
							<MaskedInput
							mask="11.111.111/1111-11" 
							value={state.cnpj || null}
							onChange={(e) => {
								setState(draft => {
									draft.cnpj = e.target.value
								})
							}}
							/>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label="Telefone">
							<MaskedInput
							mask="(11) 1111-1111" 
							value={state.phone || null}
							onChange={(e) => {
								setState(draft => {
									draft.phone = e.target.value
								})
							}}
							/>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label="Celular">
							<MaskedInput
							mask="(11) 11111-1111" 
							value={state.mobile || null}
							onChange={(e) => {
								setState(draft => {draft.mobile = e.target.value})
							}}
							/>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label="E-mail">
							<Input 
								value={state.email || null}
								onChange={(e) => {
									setState(draft => {
										draft.email = e.target.value
									})
								}}

							/>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label="Nome do Contato">
							<Input 
								value={state.contact_name || null}
								onChange={(e) => {
									setState(state => {
										state.contact_name = e.target.value
									})
								}}

							/>
						</Form.Item>
					</Col>

					<Col span={12}>
							<Form.Item label="Data de Início">
								<DatePicker 
									value={state.date_start || null}
									onChange={(d) => {
										setState(draft => {
											draft.date_start = d
										})
									}}
									format={'DD/MM/YYYY'}
									style={{width: '100%'}}
								/>
							</Form.Item>
					</Col>
					<Col span={12}>
							<Form.Item label="Data Final">
								<DatePicker 
									value={state.date_end || null}
									onChange={(d) => {
										setState(draft => {
											draft.date_end = d
										})
									}}
									format={'DD/MM/YYYY'}
									style={{width: '100%'}}
								/>
							</Form.Item>
					</Col>

				</Row>
				<br/><br/>


				<Row align="middle" style={{marginBottom: 30}}>
					<Col flex="auto">
						<Title level={3}>Funcionários</Title>
					</Col>
					<Col flex="none">
						<Button
						  onClick={() => setModalVisible(true)}
						  type="default"
						  style={{
						  	marginTop: 20,
						  	float: 'right'
						  }}
						>
						  Adicionar
						</Button>
					</Col>
				</Row>

				

				<EmployeeTable
					data={state?.employees?.length ? state?.employees : []}
					remove={removeEmployee}
					edit={editEmployee}
				/>

				<EmployeeModal
					data={modalData}
					dataIndex={modalDataIndex}
					visible={modalVisible}
					close={() => setModalVisible(false)}
					onSave={(employee, index) => {

						console.log(index);

						if(employee._id) {
							setState(draft => {
								draft.employees[index] = employee;
							})
						} else {
							setState(draft => {
								draft.employees.push(employee);
							})
						}
					}}
					
				/>

			

				<Divider />

				<div>
					<Button
					  onClick={save}
					  type="primary"
					  style={{
					  	marginTop: 20,
					  	float: 'right'
					  }}
					>
					  Salvar
					</Button>
				</div>
				


			</Form>

			
			</Content>
		</Layout>
  );
}

export default User;
