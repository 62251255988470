import { gql, useMutation, useQuery } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import {
   Layout,
   Typography,
   Button, notification, Badge, Menu,
   Dropdown,
   Table,
   Modal
} from "antd";

import dayjs from "dayjs";

import {
   DownOutlined,
   DeleteOutlined,
   StopOutlined,
   CheckCircleOutlined
} from "@ant-design/icons";

const { Content } = Layout;
const { Title } = Typography;


function PartnerKeys(props) {
	const { id } = props.match.params;

	const { data, loading, error, refetch } = useQuery(
		gql`
		query PartnerKeys($user_id: String!) {
			PartnerKeys(user_id: $user_id) {
				createdAt
				status
				key
			}
		}
	`,
		{
			variables: {
				user_id: id,
			},
			fetchPolicy: "no-cache",
		}
	);

	const [createAction, create] = useMutation(
		gql`
		mutation PartnerKey($user_id: String!) {
			PartnerKey(user_id: $user_id)
		}
	`,
		{
			variables: {
				user_id: id,
			},
			onCompleted: () => {
				refetch();
			},
		}
	);

	const [updateAction, update] = useMutation(
		gql`
		mutation PartnerKeyAction($action: String!, $user_id: String!, $key: String!) {
			PartnerKeyAction(user_id: $user_id, action: $action, key: $key) {
				result
				action
			}
		}
	`,
		{
			onCompleted: (data) => {
				if (data?.PartnerKeyAction.result) {
					refetch();
					if (data.PartnerKeyAction.action !== "delete") {
						notification.success({
							message: "Chave atualizada com sucesso",
						});
					}
					if (data.PartnerKeyAction.action === "delete") {
						notification.success({
							message: "Chave removida com sucesso",
						});
					}
				}
			},
		}
	);

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return (
			<Error
				refetch={() => {
					refetch({
						variables: { id },
					});
				}}
			/>
		);
	}

	return (
		<div>
			<div style={{ marginBottom: 20, textAlign: "right" }}>
				<Button
					onClick={() => {
						createAction();
					}}
					type="primary"
					loading={create.loading}
				>
					Criar Chave
				</Button>
			</div>
			<Table
				dataSource={data.PartnerKeys}
				loading={create.loading}
				columns={[
					{
						title: "Chave",
						key: "key",
						dataIndex: "key",
						render: (value) => <code>{value}</code>,
					},
					{
						title: "Status",
						dataIndex: "status",
						key: "status",
						width: 100,
						render: (value) => {
							if (value === "active")
								return (
									<>
										<Badge status="success" /> Ativo
									</>
								);
							if (value === "inactive")
								return (
									<>
										<Badge status="error" /> Inativo
									</>
								);
						},
					},
					{
						title: "Criado em",
						key: "createdAt",
						dataIndex: "createdAt",
						width: 200,
						render: (value) => dayjs(value).format("DD/MM/YYYY HH:mm:ss"),
					},
					{
						title: "Ações",
						key: "actions",
						dataIndex: "actions",
						width: 100,
						render: (value, row) => {
							const menu = (
								<Menu>
									{row.status === "active" ? (
										<Menu.Item
											icon={<StopOutlined />}
											onClick={() => {
												updateAction({
													variables: {
														user_id: id,
														action: "disable",
														key: row.key,
													},
												});
											}}
										>
											Desativar
										</Menu.Item>
									) : null}
									{row.status === "inactive" ? (
										<Menu.Item
											icon={<CheckCircleOutlined />}
											onClick={() => {
												updateAction({
													variables: {
														user_id: id,
														action: "activate",
														key: row.key,
													},
												});
											}}
										>
											Ativar
										</Menu.Item>
									) : null}

									<Menu.Item
										icon={<DeleteOutlined />}
										danger
										onClick={() => {
											Modal.confirm({
												title: "Remover chave",
												content:
													"Tem certeza que deseja remover esta chave? Esta ação não pode ser desfeita.",
												cancelText: "Cancelar",
												onOk: () => {
													console.log("aaa");
													updateAction({
														variables: {
															user_id: id,
															action: "delete",
															key: row.key,
														},
													});
												},
											});
										}}
									>
										Remover
									</Menu.Item>
								</Menu>
							);

							return (
								<div style={{ display: "flex" }}>
									<Dropdown
										placement="bottomRight"
										overlay={menu}
										trigger={["click"]}
										arrow
									>
										<Button>
											Ações <DownOutlined />
										</Button>
									</Dropdown>
								</div>
							);
						},
					},
				]}
			/>
		</div>
	);
}



export default PartnerKeys;
