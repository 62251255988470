import { Drawer, Form, Button, DatePicker, Select, Input } from "antd";
import MaskedInput from "antd-mask-input";
import Estados from "functions/states";

export default function Filters({
	setFilters,
	filters,
	visible,
	close,
	filter,
}) {
	return (
		<Drawer
			title="Filtros"
			visible={visible}
			width={600}
			onClose={() => {
				close();
			}}
		>
			<Form layout="vertical">
				<Form.Item label="CNPJ">
					<MaskedInput
						mask={"11.111.111/1111-11"}
						value={filters?.cnpj}
						onChange={(e) => {
							setFilters((d) => {
								d.cnpj = e.target.value;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label="Tipo">
					<Select
						value={filters.type}
						onChange={(e) => {
							setFilters((d) => {
								d.type = e;
							});
						}}
						allowClear
					>
						<Select.Option value="">Todos</Select.Option>
						<Select.Option value="accountant">Contador</Select.Option>
						<Select.Option value="business">Empresário (Individual)</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item label="CNAE">
					<Input
						value={filters?.cnae}
						onChange={(e) => {
							setFilters((d) => {
								d.cnae = e.target.value;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label="Período de Contato">
					<DatePicker.RangePicker
						format={"DD/MM/YYYY"}
						allowClear
						style={{ width: "100%" }}
						value={filters?.contact_dates}
						onChange={(e) => {
							setFilters((d) => {
								d.contact_dates = e;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label="Período de Retorno">
					<DatePicker.RangePicker
						allowClear
						format={"DD/MM/YYYY"}
						style={{ width: "100%" }}
						value={filters?.return_date}
						onChange={(e) => {
							setFilters((d) => {
								d.return_date = e;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label="Data Início Atividades">
					<DatePicker.RangePicker
						allowClear
						format={"DD/MM/YYYY"}
						style={{ width: "100%" }}
						value={filters?.started_date}
						onChange={(e) => {
							setFilters((d) => {
								d.started_date = e;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label="Status da Negociação">
					<Select
						value={filters.negotiation_status}
						onChange={(e) => {
							setFilters((d) => {
								d.negotiation_status = e;
							});
						}}
						allowClear
						placeholder={"Status Negociação"}
					>
						<Select.Option value="Fechou">Fechou</Select.Option>
						<Select.Option value="Não tem interesse">
							Não tem interesse
						</Select.Option>
						<Select.Option value="Em negociação">Em negociação</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item label="Cliente Respondeu">
					<Select
						value={filters.replied}
						onChange={(e) => {
							setFilters((d) => {
								d.replied = e;
							});
						}}
						allowClear
						placeholder={"Cliente Respondeu"}
					>
						<Select.Option value="Sim">Sim</Select.Option>
						<Select.Option value="Não">Não</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item label="Estado">
					<Select
						value={filters.state}
						onChange={(e) => {
							setFilters((d) => {
								d.state = e;
							});
						}}
						allowClear
					>
						{Estados("all").map((item) => {
							return (
								<Select.Option key={item.value} value={item.value}>
									{item.text}
								</Select.Option>
							);
						})}
					</Select>
				</Form.Item>

				<div>
					<Button
						type="primary"
						onClick={() => {
							filter();
							close();
						}}
					>
						Filtrar
					</Button>
				</div>
			</Form>
		</Drawer>
	);
}
