import React, {useState, useEffect} from 'react';
import User from 'store/user';
import { Layout, Avatar, Menu, Dropdown, Input, Form, AutoComplete, Button, Switch } from 'antd';
import { Link, NavLink } from "react-router-dom";
import debounce from 'functions/debounce';
import { useLazyQuery } from "@apollo/client";
import * as Queries from './Queries';
import { useHistory } from "react-router-dom";
import { useThemeSwitcher } from "react-css-theme-switcher";
import Icon from '@ant-design/icons';

import { ReactComponent as Moon } from 'assets/icon/moon.svg';
import { ReactComponent as Sun } from 'assets/icon/sun.svg';

import { 
	UserOutlined,
	SearchOutlined,
	LoadingOutlined
} from '@ant-design/icons';


const { Header } = Layout;
const { Option } = AutoComplete;



function HeaderView(props) {

	const history = useHistory();
	const [results, setResults] = useState(null);
	const [search, { loading, data }] = useLazyQuery(Queries.SEARCH);
	const onSearch = debounce(e => searchAction(e), 500);

	//
	// THEME
	//

	const { switcher, currentTheme, status, themes } = useThemeSwitcher();
	const [isDarkMode, setIsDarkMode] = useState();

	const toggleTheme = (isChecked) => {
	    setIsDarkMode(isChecked);
	    switcher({ theme: isChecked ? themes.dark : themes.light });
	    window.localStorage.setItem("theme", isChecked ? "dark" : "light");
	};

	//
	// ACTIONS
	//

	const searchAction = async (term) => {
		await search({
			variables: {
				term
			}
		});
	}

	useEffect(() => {
		if(currentTheme === 'dark') setIsDarkMode(true);
	}, []);

	useEffect(() => {
		if(data?.UserSearch?.length) {
			setResults(data?.UserSearch);
		} else {
			setResults([]);
		}
	}, [data]);

	const logout = () => {
		User.logout().then(() => {
			history.push("/login");
		});
	}

	const submenu = () => {
		return (
			<Menu>
			    <Menu.Item>
			      <a target="_blank" rel="noopener noreferrer" onClick={logout}>
			       Sair
			      </a>
			    </Menu.Item>
			  </Menu>
		)
	}

	const onSelect = (e, opt) => {
		props?.history?.push(`/user/${opt.key}`);
	};



	return (
		<Header
			className="header-view"
		>
			<div className="header-inset-view">
					<div className="search-view">
						<SearchOutlined/>
						<AutoComplete
						  onSelect={onSelect}
						  onChange={onSearch}
						  style={{
						  	width: '100%',
						  	marginRight: 10,
						  }}
						  placeholder="Buscar Usuários"
						>
							{results?.length && results.map((item) => (
							  <Option key={item._id} value={item.name}>
							    {item.name}
							  </Option>
							))}
						</AutoComplete>
					</div>

					<div className="user-info">
						 <span className="user-name"><strong>{User.get('name')}</strong></span>
						<div className="user-avatar">
							<UserOutlined style={{color: '#593d88', fontSize: '20px', cursor: 'pointer'}} />
						</div>
						<Button type="link" onClick={logout}>Sair</Button>

						 <Switch 
						 	checked={isDarkMode} 
						 	onChange={toggleTheme}
						 	checkedChildren={<Icon component={Moon} />}
						 	unCheckedChildren={<Icon component={Sun} />}
						 />
					</div>
			</div>
			


		</Header>
	);
}

export default HeaderView;