import { gql } from '@apollo/client';

export const DASHBOARD = gql`
  {
    Dashboard {
      users {
        active
        need_business
        waiting_business
        inactive
        created_today
        created_month
        plan_super
        plan_top
      }
      tickets {
        awaiting
        in_progress
        solved
        waiting_customer
      }
      partners {
        Last_30days
        Last_60days
        Last_90days
        Total
      }
    }
  }

`;
