import React, { useState, useEffect } from "react";
import * as Queries from "./Queries";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import MaskedInput from "antd-mask-input";
import {
	Modal,
	Button,
	Col,
	Row,
	Layout,
	Form,
	Input,
	Select,
	Alert,
	DatePicker,
	Upload,
	notification,
} from "antd";
import InputNumber from "components/InputNumber";
import { useMutation, useLazyQuery, gql } from "@apollo/client";
import Swal from "sweetalert2";
import UserSearch from "components/UserSearch";
import { useImmer } from "use-immer";
import moment from "moment";

function ModalView({ visible, refetch, close }) {
	const [create, { loading, error }] = useMutation(
		gql`
		mutation DASInstallment($input: DASInstallmentInput) {
			DASInstallment(input: $input) {
				_id
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);
	const [state, setState] = useImmer({});

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	const onClose = () => {
		setState({});
		close();
	};

	const save = async () => {
		if (!state?.business_id)
			return ErrorDialog("Por favor, selecione um usuário");
		if (!state?.date) return ErrorDialog("Por favor, selecione uma data");
		if (!state?.installments_number)
			return ErrorDialog("Por favor, informe a quantidade de parcelas");
		if (!state?.current_installment_number)
			return ErrorDialog("Por favor, informe o número da parcela atual");
		if (state?.installments_number > 60)
			return ErrorDialog("O número máximo de parcelas é 60");

		if (!state?.totalAmount)
			return ErrorDialog("Por favor, informe o valor total");

		let { user_name, ...input } = state;
		input.date = input.date.toISOString();

		try {
			let res = await create({
				variables: {
					input,
				},
			});

			if (res?.data?.DASInstallment?._id) {
				refetch();
				close();
				setState({});
			} else {
				ErrorDialog("Não foi possível completar a solicitação.");
			}
		} catch (error) {
			ErrorDialog(
				error?.message ?? "Não foi possível completar a solicitação."
			);
		}
	};

	return (
		<Modal
			title="Novo Parcelamento"
			visible={visible}
			onOk={save}
			onCancel={onClose}
			footer={[
				<Button key="back" onClick={onClose} disabled={loading}>
					Cancelar
				</Button>,
				<Button
					type="primary"
					loading={loading}
					disabled={loading}
					onClick={save}
				>
					Enviar
				</Button>,
			]}
		>
			<Form layout="vertical">
				{visible && (
					<>
						<Form.Item label="Usuário">
							<UserSearch
								style={{ width: "100%" }}
								value={state?.user_name}
								onChange={(value, data) => {
									setState((d) => {
										d.user_name = data.name;
										d.business_id = data.business_id;
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="Data do Parcelamento">
							<DatePicker
								format={"DD/MM/YYYY"}
								value={state?.date}
								onChange={(e) => {
									setState((d) => {
										d.date = e;
									});
								}}
								style={{ width: "100%" }}
								disabledDate={(current) => {
									const start = moment("2018-01-01", "YYYY-MM-DD");
									return current < start || current > moment();
								}}
							/>
						</Form.Item>
						<Form.Item label="Número de Parcelas">
							<Input
								type="number"
								value={state?.installments_number}
								min={1}
								max={60}
								onChange={(e) => {
									setState((d) => {
										d.installments_number = e.target.value;
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="Número da Parcela Atual">
							<Input
								type="number"
								value={state?.current_installment_number}
								min={1}
								max={60}
								onChange={(e) => {
									setState((d) => {
										d.current_installment_number = e.target.value;
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="Valor Total">
							<InputNumber
								value={state?.totalAmount}
								onChange={(e) => {
									setState((d) => {
										d.totalAmount = e;
									});
								}}
							/>
						</Form.Item>
					</>
				)}
			</Form>
		</Modal>
	);
}

export default ModalView;
