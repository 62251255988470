import {
	Layout,
	Typography,
	Button,
	Table,
	Tag,
	Modal,
	Descriptions,
	Divider,
	Spin,
	Form,
	Input,
	Row,
	Col,
	DatePicker,
	Select,
	notification,
} from "antd";
import { useImmer } from "use-immer";
import MaskedInput from "antd-mask-input";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import Import from "./Import";
import Filters from "./Filters";
import { useEffect } from "react";
import moment from "moment";
import Mask from "functions/mask";

const { Content } = Layout;
const { Title } = Typography;

const PROSPECT_DATA = `
_id
data_inicio_atividade
situacao_cadastral
cnpj
cnpj_raiz
nome_fantasia
razao_social
atividade_principal {
	classe
	subclasse
	descricao
}
estabelecimento_endereco {
	complemento
	numero
	logradouro
	bairro
	cep
	cidade_nome
	estado_nome
	estado_sigla
}
info {
	contact_name
	contact_form
	contact_date
	replied
	proposal_made
	negotiation_status
	return_date
}
telefone
email`;

function Detail({ visible, close, id }) {
	const [state, setState] = useImmer({});
	const [data, setData] = useImmer(null);

	const [update, { loading: updating }] = useMutation(
		gql`
		mutation ProspectUpdate($input: ProspectUpdateInfo!, $id: String!) {
			ProspectUpdate(input: $input, id: $id)
		}
	`,
		{
			onCompleted: (data) => {
				if (data.ProspectUpdate) {
					notification.success({
						description: "Informações salvar com sucesso",
					});
				}
			},
			onError: (err) => {
				notification.error({
					description: "Não foi possível salvar no momento",
				});
			},
		}
	);

	const [loadData, { loading }] = useLazyQuery(
		gql`
		query Prospect($id: String!) {
			Prospect(id: $id) {
				${PROSPECT_DATA}
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
			onCompleted: (data) => {
				if (data.Prospect) {
					let { info, ...response } = data.Prospect;
					if (info) {
						if (info.contact_date)
							info.contact_date = moment(info.contact_date);
						if (info.return_date) info.return_date = moment(info.return_date);
						setState(info);
					}
					setData(response);
				}
			},
		}
	);

	useEffect(() => {
		if (visible && id) {
			loadData({
				variables: {
					id,
				},
			});
		}
		if (!visible) {
			setData(null);
			setState({});
		}
	}, [visible, id]);

	const save = () => {
		let payload = { ...state };
		if (payload.contact_date)
			payload.contact_date = payload.contact_date.toISOString();
		if (payload.return_date)
			payload.return_date = payload.return_date.toISOString();

		update({
			variables: {
				input: payload,
				id: id,
			},
		});
	};

	return (
		<Modal
			title="Sobre"
			visible={visible}
			width={800}
			onCancel={!updating ? close : null}
			footer={[
				<Button
					type="primary"
					loading={updating}
					disabled={updating}
					onClick={save}
				>
					Salvar
				</Button>,
			]}
		>
			{loading && <Spin />}
			{data && (
				<div>
					<Descriptions title="Informações" bordered>
						<Descriptions.Item label="CNPJ" span={3}>
							{data.cnpj}
						</Descriptions.Item>
						<Descriptions.Item label="Telefone">
							{data.telefone}
						</Descriptions.Item>
						<Descriptions.Item label="E-mail" span={2}>
							{data.email}
						</Descriptions.Item>
						<Descriptions.Item label="Razão Social" span={3}>
							{data.razao_social}
						</Descriptions.Item>
						<Descriptions.Item label="Data Inicio Atividade" span={3}>
							{data.data_inicio_atividade}
						</Descriptions.Item>
						<Descriptions.Item label="Endereço" span={3}>
							{data?.estabelecimento_endereco?.logradouro},{" "}
							{data?.estabelecimento_endereco?.numero}{" "}
							{data?.estabelecimento_endereco?.complemento} <br></br>{" "}
							{data?.estabelecimento_endereco?.bairro} <br></br>{" "}
							{data?.estabelecimento_endereco?.cidade_nome}/
							{data?.estabelecimento_endereco?.estado_sigla} <br></br> CEP{" "}
							{data?.estabelecimento_endereco?.cep}
						</Descriptions.Item>
					</Descriptions>

					<Divider />

					<Descriptions title="Atividade Principal" bordered>
						<Descriptions.Item label="Classe">
							{data.atividade_principal.classe}
						</Descriptions.Item>
						<Descriptions.Item label="Subclasse" span={2}>
							{data.atividade_principal.subclasse}
						</Descriptions.Item>
						<Descriptions.Item label="Descrição" span={3}>
							{data.atividade_principal.descricao}
						</Descriptions.Item>
					</Descriptions>

					<Divider />

					<Form layout="vertical">
						<Row gutter={20}>
							<Col span={12}>
								<Form.Item label="Pessoa de Contato">
									<Input
										value={state?.contact_name}
										onChange={(e) => {
											setState((d) => {
												d.contact_name = e.target.value;
											});
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Data de Contato">
									<DatePicker
										format={"DD/MM/YYYY"}
										style={{ width: "100%" }}
										value={state?.contact_date}
										onChange={(e) => {
											setState((d) => {
												d.contact_date = e;
											});
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Data de Retorno">
									<DatePicker
										format={"DD/MM/YYYY"}
										style={{ width: "100%" }}
										value={state?.return_date}
										onChange={(e) => {
											setState((d) => {
												d.return_date = e;
											});
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Forma de Contato">
									<Input
										value={state?.contact_form}
										onChange={(e) => {
											setState((d) => {
												d.contact_form = e.target.value;
											});
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Cliente Respondeu">
									<Select
										value={state?.replied}
										onChange={(e) => {
											setState((d) => {
												d.replied = e;
											});
										}}
									>
										<Select.Option value="Sim">Sim</Select.Option>
										<Select.Option value="Não">Não</Select.Option>
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Proposta Feita">
									<Select
										value={state?.proposal_made}
										onChange={(e) => {
											setState((d) => {
												d.proposal_made = e;
											});
										}}
									>
										<Select.Option value="Sim">Sim</Select.Option>
										<Select.Option value="Não">Não</Select.Option>
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Status da Negociação">
									<Select
										value={state?.negotiation_status}
										onChange={(e) => {
											setState((d) => {
												d.negotiation_status = e;
											});
										}}
									>
										<Select.Option value={"deal"}>Fechou </Select.Option>
											<Select.Option value={"negotiating"}>Em negociação </Select.Option>
											<Select.Option value={"contract_sent"}>Contrato enviado</Select.Option>
											<Select.Option value={"contract_signed"}>Contrato assinado</Select.Option>
											<Select.Option value={"client_dropped"}>Cliente desistiu</Select.Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
			)}
		</Modal>
	);
}

function ProspectList() {

	

	const [modal, setModal] = useImmer({
		visible: false,
	});

	const [filterModal, setFilterModal] = useImmer(false);
	const [loadingPagination, setLoadingPagination] = useImmer(false);
	const [filters, setFilters] = useImmer({});

	const [load, { data, loading, error, refetch }] = useLazyQuery(
		gql`
		query Prospects($filters: ProspectsFilters, $page: Int!) {
			Prospects(filters: $filters, page: $page) {
				prospects {
					${PROSPECT_DATA}
				}
				currentPage
				pages
				total
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
			variables: {
				page: 1,
			},
		}
	);
	

	useEffect(() => {
		filter();
	}, []);

	const filter = () => {
		load({
			variables: {
				page: 1,
				filters,
			},
		});
	};

	if(!process?.env?.REACT_APP_PROSPECTS === "1") return (<div></div>);

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>CRM Prospecção</Title>
					<div
						style={{
							display: "flex",
							gap: 10,
						}}
					>
						<Import refetch={refetch} />
						<Button
							onClick={() => {
								setFilterModal(true);
							}}
						>
							Filtros
						</Button>
					</div>
				</div>

				<Filters
					visible={filterModal}
					close={() => {
						setFilterModal(false);
					}}
					setFilters={setFilters}
					filters={filters}
					filter={filter}
				/>

				<div className="dashboard">
					{loading ? <Loading /> : null}

					{!loading && !error && data ? (
						<div>
							<Table
								rowKey="_id"
								dataSource={data?.Prospects?.prospects}
								pagination={{
									hideOnSinglePage: true,
									defaultCurrent: data.Prospects.currentPage,
									total: data.Prospects.total,
									showSizeChanger: false,
									defaultPageSize: 10,
								}}
								loading={loadingPagination}
								onChange={async (pagination) => {
									setLoadingPagination(true);
									await refetch({
										page: pagination.current,
										filters,
									});
									setLoadingPagination(false);
								}}
								columns={[
									{
										key: "razao_social",
										dataIndex: "razao_social",
										title: "Razão Social",
									},
									{
										key: "cnpj",
										dataIndex: "cnpj",
										title: "CNPJ",
										render: (value) => {
											return value ? Mask(value.replace(/\D/g,''), "99.999.999/9999-99") : '-';
										}
									},
									{
										key: "situacao_cadastral",
										dataIndex: "situacao_cadastral",
										title: "Situação Cadastral",
										render: (value) => {
											return <Tag>{value}</Tag>;
										},
									},
									{
										key: "data_inicio_atividade",
										dataIndex: "data_inicio_atividade",
										title: "Inicio Atividades",
									},
									{
										key: "state_city",
										dataIndex: "state_city",
										title: "Localização",
										render: (value, row) => {
											return (
												<div>
													{row?.estabelecimento_endereco?.cidade_nome}/
													{row?.estabelecimento_endereco?.estado_sigla}
												</div>
											);
										},
									},
									{
										key: "action",
										dataIndex: "action",
										title: "",
										width: 100,
										render: (value, row) => {
											return (
												<Button
													onClick={() => {
														setModal({
															visible: true,
															id: row._id,
														});
													}}
												>
													Detalhes
												</Button>
											);
										},
									},
								]}
							/>
						</div>
					) : null}

					{!loading && error ? <Error refetch={() => refetch()} /> : null}
				</div>

				<Detail
					{...modal}
					close={() => {
						setModal({
							visible: false,
						});
					}}
				/>
			</Content>
		</Layout>
	);
}

export default ProspectList;
