import React, { useState, useEffect } from "react";
import * as Queries from "./Queries";
import { useQuery } from "@apollo/client";
import _ from 'lodash';
import MaskedInput from 'antd-mask-input';
import {statuses} from './Misc';
import { Modal, Button, Col, Row, Layout, Form, Input, Select } from "antd";
import InputNumber from 'components/InputNumber';
import { useMutation } from '@apollo/client';
import Swal from 'sweetalert2';
import isEmail from 'validator/lib/isEmail';

function ModalView({ visible, refetch, data, close }) {

	const [state, setState] = useState(null);
	const [saveAction] = useMutation(Queries.ADMIN_SAVE);

	const save = async () => {
		
		try {
			const {_id, ...object} = data;


			if(!object.name) return Swal.fire({
					title: "Erro",
					text: 'Por favor, insira um nome válido',
					type: "error",
					confirmButtonText: "OK"
			});
			if(!object.email || !isEmail(object.email)) return Swal.fire({
					title: "Erro",
					text: 'Por favor, insira um e-mail válido',
					type: "error",
					confirmButtonText: "OK"
			});

			if(!state._id && !object.password) return Swal.fire({
					title: "Erro",
					text: 'Por favor, insira uma senha',
					type: "error",
					confirmButtonText: "OK"
			});

			if(!object.area) return Swal.fire({
					title: "Erro",
					text: 'Por favor, selecione um pólo.',
					type: "error",
					confirmButtonText: "OK"
			});

			if(!object.status) return Swal.fire({
					title: "Erro",
					text: 'Por favor, selecione um status.',
					type: "error",
					confirmButtonText: "OK"
			});


			console.log(object);

			const response = await saveAction({
				variables: {
					input: {
						...object,
						status: object.status.toString()
					},
					id: _id ? _id : null
				}
			});

			if(!response.error) {
				close();
				setState(null);
				refetch();
			} else {
				Swal.fire({
					title: "Erro",
					text: 'Não foi possível salvar no momento. Por favor, tente novamente mais tarde.',
					type: "error",
					confirmButtonText: "OK"
				});
			}


		} catch(e) {
			console.log(e);
			Swal.fire({
				title: "Erro",
				text: 'Não foi possível salvar no momento. Por favor, tente novamente mais tarde.',
				type: "error",
				confirmButtonText: "OK"
			});
		}

		
	};

	const getValue = (path) => {
		return _.get(state, path);
	}

	const setValue = (path, value) => {
		setState(state => {
			_.set(state, path, value);
			return state;
		})
	}

	useEffect(() => {
		setState(data);
	}, [data]);


	return (
		<Modal
			title="Serviço"
			visible={visible}
			onOk={save}
			onCancel={() => {
				setState(null);
				close();
			}}
		>
			{
				state ? (
					<Form
					layout="vertical"
					>

						<Form.Item
							name="name"
							label="Nome"
						>
							<Input 
								defaultValue={getValue('name')}
								onChange={(e) => {
									const {value} = e.target;
									setValue('name', value);
								}}
							/>
						</Form.Item>

						<Form.Item
							name="email"
							label="E-mail"
						>
							<Input 
								defaultValue={getValue('email')}
								onChange={(e) => {
									const {value} = e.target;
									setValue('email', value);
								}}
							/>
						</Form.Item>

						<Form.Item
							name="password"
							label="Senha"
						>
							<Input.Password
								onChange={(e) => {
									const {value} = e.target;
									setValue('password', value);
								}}
							/>
							{
								state._id ? (
									<span style={{fontSize: '12px'}}>Deixar em branco caso não queira alterar</span>
								) : null
							}
						</Form.Item>

						<Form.Item label="Pólo">
						  <Select
						  	defaultValue={getValue('area')}
						  	onChange={(e) => {
						  		console.log(e);
						  		setValue('area', e);
						  	}}
						  >
						    <Select.Option value="Back Office">Back Office</Select.Option>
						  </Select>
						</Form.Item>
		

						<Form.Item label="Status">
						  <Select
						  	defaultValue={getValue('status')}
						  	onChange={(e) => {
						  		setValue('status', e);
						  	}}
						  >
						    <Select.Option value="0">Inativo</Select.Option>
						    <Select.Option value="1">Ativo</Select.Option>
						  </Select>
						</Form.Item>

					</Form>
				) : null
			}
		</Modal>
	);
}

export default ModalView;
